<template>
    <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <CIcon name="cil-justify-center"/>
            <strong>Accomplishment Report</strong>
            <div class="card-header-actions">
              <a 
                href="#" 
                class="card-header-action" 
                rel="noreferrer noopener" 
                target="_blank"
              >
                <small class="text-muted">AR</small>
              </a>
            </div>
          </CCardHeader>
          <CCardBody >
                 <div class="row">
                      <div class="form-group ml-4">
                            <!-- <button class="ml-auto mr-3 btn btn-primary btn-sm" style="height:27px" @click="addPlan">Add Plan</button> -->
                            <button class="ml-auto mr-3 btn btn-success btn-sm" style="height:27px" @click="addAccomplishment">Accomplishment</button>
                            <!-- <button class="ml-auto mr-3 btn btn-warning btn-sm text-white" style="height:27px" @click="openNatureAWA">Nature of AWA</button> -->
                            <!-- <button class="ml-auto mr-3 btn btn-warning btn-sm text-white" style="height:27px" @click="openNatureWork">Nature of WORK</button> -->
                            <button class="ml-auto mr-3 btn btn-warning btn-sm text-white" style="height:27px" @click="openIPCRIndicator">IPCR Indicator</button>
                            <!-- <button class="ml-auto mr-3 btn btn-warning btn-sm text-white" style="height:27px" @click="openTypeTransaction">Type of Transaction</button> -->

                      </div>
                      <div class="form-group">
                        <span class="ml-2">From: </span>
                        <input class="ml-2" type="date" v-model="from"/>
                      </div>
                      <div class="form-group">
                        <span class="ml-2">To: </span>
                        <input class="ml-2" type="date" v-model="to"/>
                      </div>
                      <button class="ml-2 btn btn-primary btn-sm" @click="searchLogs(from, to)" style="height:27px" v-if="!loading">GENERATE</button>
                      <button class="ml-2 btn btn-success btn-sm" style="height:27px" v-if="preview" @click="previewAR">PREVIEW</button>
                      <button class="ml-2 btn btn-success btn-sm" style="height:27px" v-if="unpreview" @click="unpreviewAR">UNPREVIEW</button>
                      <button class="ml-2 btn btn-primary btn-sm" type="button" disabled v-if="loading" style="height:27px">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Searching...
                      </button>
                      <button class="ml-2 btn btn-primary btn-sm d-flex ml-auto mr-2" style="height:27px" @click="printAR">PRINT</button>
                  </div>
                  <form @submit.prevent="storeCSVtoDatabase">
                  </form>
                     <CRow v-if="!printPreview">
                      <CCol lg="12">
                        <accomplishment-report :accomplisment_reports="get_accomplishment_reports" @updateAR="openUpdateAR" @deleteAR="deleteActivities">
                       <template #header>
                            <button v-if="!filter" class="btn btn-info" @click="openFilter">Filter Indicator</button>
                            <button v-if="filter" class="btn btn-secondary text-white" @click="closeFilter">Close</button>
                            <button v-if="filter" class="btn btn-danger text-white ml-1" @click="resetFilter">Reset</button>
                            <div v-if="filter">
                              <div class="row">
                                  <div class="col-md-3 mt-4"><span class="font-weight-bold">Total items filtered:</span> {{total_filters}}</div>
                                  <div class="col-md-1"></div>
                                  <div class="col-md-2"></div>
                                  <div class="col-md-2">
                                      <label class="font-weight-bold mt-4">OPCR Indicator</label>
                                      <select v-model="ar.ar_opcr" class="form-control" required @change="filterOPCR">
                                        <option 
                                          v-for="(option, i) in get_opcrs"
                                          :key="i"
                                          :value="option.value"
                                        >{{option.text}}</option>
                                      </select>
                                  </div>
                                  <div class="col-md-2">
                                      <label class="font-weight-bold mt-4">OPCR SUB Indicator</label>
                                      <select v-model="ar.ar_opcr_sub" class="form-control" required @change="filterOPCR_SUB">
                                        <option 
                                          v-for="(option, i) in get_opcrs_subs"
                                          :key="i"
                                          :value="option.value"
                                        >{{option.text}}</option>
                                      </select>
                                  </div>
                                  <div class="col-md-2">
                                      <label class="font-weight-bold mt-4">IPCR Indicator</label>
                                      <select v-model="ar.ar_ipcr_indicator" class="form-control" required @change="filterIndicator">
                                        <option 
                                          v-for="(option, i) in get_ipcr"
                                          :key="i"
                                          :value="option.ar_ipcr_indicator_id"
                                        >{{option.ipcr_text}}</option>
                                      </select>
                                  </div>
                                </div>
                            </div>
                          </template>
                        </accomplishment-report>
                      </CCol>
                     </CRow>
                  <!------------ Start- View Nature WORK Modal ------------>
                  <div id="open-nature-work" class="modal fade">
                    <div class="modal-dialog modal-lg">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title">Nature of WORK</h5>

                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        
                          <nature-work :nature_work="get_work" @updateWork="openUpdateWork">
                            <template #header>
                              <div class="row">
                                <button class="btn btn-success ml-4" @click="addWork=true, add_work=''" type="submit" v-if="!addWork">Add Nature of WORK</button>
                              </div>
                              <div class="card-header-actions">
                              </div>
                              <div v-if="addWork">
                                <form @submit.prevent="submitWork">
                                  <div class="modal-body">
                                      <div class="mb-3">
                                        <div class="row">
                                          <div class="col-md-12">
                                              <label>Nature of Work</label>
                                              <input type="text"  class="form-control" v-model="add_work">
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button class="btn btn-danger" data-dismiss="modal">Close</button>
                                    <button class="btn btn-primary" type="submit" v-if="!loading">Add</button>
                                    <button class="btn btn-primary" type="button" disabled v-if="loading">
                                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                      Saving...
                                    </button>
                                  </div>
                                </form>
                              </div>
                              <div v-if="!addWork">
                                <form @submit.prevent="submitUpdateWork">
                                  <div class="modal-body">
                                      <div class="mb-3">
                                        <div class="row">
                                          <div class="col-md-12">
                                              <label>Nature of Work</label>
                                              <input type="text"  class="form-control" v-model="edit_work.work_text">
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button class="btn btn-danger" data-dismiss="modal">Close</button>
                                    <button class="btn btn-primary" type="submit" v-if="!loading">Update</button>
                                    <button class="btn btn-primary" type="button" disabled v-if="loading">
                                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                      Saving...
                                    </button>
                                  </div>
                                </form>
                              </div>

                            </template>
                          </nature-work>
                        <!-- END OF MODAL-BODY -->

                        <div class="modal-footer">
                          <!-- <button class="btn btn-danger" data-dismiss="modal">Close</button> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!------------ End - View Nature WORK Modal ------------>
                  <!------------ Start- View IPCR Indicator Modal ------------>
                  <div id="open-ipcr-indicator" class="modal fade">
                    <div class="modal-dialog modal-lg">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title">IPCR Indicator</h5>

                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        
                          <ipcr-indicator :ipcr_indicator="get_ipcr" @updateIPCR="openUpdateIPCR">
                            <template #header>
                              <div class="row">
                                <button class="btn btn-success ml-4" @click="addIPCR=true, add_ipcr=''" type="submit" v-if="!addIPCR">Add IPCR Indicator</button>
                              </div>
                              <div class="card-header-actions">
                              </div>
                              <div v-if="addIPCR">
                                <form @submit.prevent="submitIPCR">
                                  <div class="modal-body">
                                      <div class="mb-3">
                                        <div class="row">
                                          <div class="col-md-12">
                                              <label>IPCR Indicator</label>
                                              <input type="text"  class="form-control" v-model="add_ipcr">
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button class="btn btn-danger" data-dismiss="modal">Close</button>
                                    <button class="btn btn-primary" type="submit" v-if="!loading">Add</button>
                                    <button class="btn btn-primary" type="button" disabled v-if="loading">
                                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                      Saving...
                                    </button>
                                  </div>
                                </form>
                              </div>
                              <div v-if="!addIPCR">
                                <form @submit.prevent="submitUpdateIPCR">
                                  <div class="modal-body">
                                      <div class="mb-3">
                                        <div class="row">
                                          <div class="col-md-12">
                                              <label>IPCR Indicator</label>
                                              <input type="text"  class="form-control" v-model="edit_ipcr.ipcr_text">
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button class="btn btn-danger" data-dismiss="modal">Close</button>
                                    <button class="btn btn-primary" type="submit" v-if="!loading">Update</button>
                                    <button class="btn btn-primary" type="button" disabled v-if="loading">
                                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                      Saving...
                                    </button>
                                  </div>
                                </form>
                              </div>

                            </template>
                          </ipcr-indicator>
                        <!-- END OF MODAL-BODY -->

                        <div class="modal-footer">
                          <!-- <button class="btn btn-danger" data-dismiss="modal">Close</button> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!------------ End - View IPCR Indicator Modal ------------>
                  <div id="ar" v-if="printPreview">
                      <div class="container mt-4" >
                        <div class="text-right">
                            <h6>Ref. No. {{reference()}}</h6>
                        </div>
                          <img
                            src="@/assets/logo/header.png"
                            alt=""
                            style="position: absolute; margin-left: 24%; margin-top: -3%; "
                          />
                        <div class="text-center font-weight-bold" style="margin-top: 100px">WEEKLY ACCOMPLISHMENTS</div>
                        <div class="text-center">Month/Year: {{getDate()}}</div>
                        <div>Division: <span class="font-weight-bold"> {{ getDivision($store.state.user.personnel_id) }} </span></div>
                        <div>Unit/Section: <span class="font-weight-bold"> {{ getSection($store.state.user.personnel_id) }} </span></div>

                        <table style="border: 1px solid; width: 100%">
                            <tr style="border: 1px solid">
                              <td class="text-center font-weight-bold" colspan="2" style="border: 1px solid">ACCOMPLISHMENTS</td>
                            </tr>
                            <tr style="border: 1px solid">
                              <td class="text-center" style="border: 1px solid">Date</td>
                              <td class="text-center" style="border: 1px solid">Activities/Tasks</td>
                            </tr>
                            <tr v-for="(get_accomplishment_date,i) in get_accomplishment_dates" :key="i" style="border: 1px solid">
                              <td class="p-2">{{ formatDate(get_accomplishment_date.date_of_work) }}</td>
                              <td style="border: 1px solid" class="p-2">
                                      <ul v-for="(get_accomplishment,i) in get_accomplishmentsFunc(get_accomplishment_date.date_of_work)" :key="i">
                                          <li>{{ get_accomplishment.details }} </li>
                                      </ul>
                              </td>
                            </tr>
                        </table>

                        <!-- <div class="row mt-4" style="border:1px solid; border-bottom: hidden" >
                            <div class="col-md-12 text-center font-weight-bold" style="border:1px solid; border-left: hidden;border-top:hidden;border-right:hidden">ACCOMPLISHMENTS</div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center font-weight-bold">
                              <div class="row" style="border:1px solid; border-top: hidden">
                                    <div class="col-md-3" style="border:1px solid; border-top: hidden;border-left:hidden;border-bottom:hidden">Date</div>
                                    <div class="col-md-9">Activities/Tasks</div>
                              </div>
                            </div>
                        </div> -->
                        <!-- <div class="row">
                            <div class="col-md-12 text-left">
                              <div class="row" v-for="(get_accomplishment_date,i) in get_accomplishment_dates" :key="i">
                                    <div class="col-md-3" style="border:1px solid; border-top: hidden;border-top: hidden" >
                                      <div>{{ formatDate(get_accomplishment_date.date_of_work) }}</div>
                                    </div>
                                    <div class="col-md-9" style="border: 1px solid;  border-top: hidden;border-left:hidden"  >
                                      <ul v-for="(get_accomplishment,i) in get_accomplishmentsFunc(get_accomplishment_date.date_of_work)" :key="i">
                                          <li>{{ get_accomplishment.details }} </li>
                                      </ul>
                                    </div>
                              </div>
                            </div>
                        </div> -->
                        <table style="width: 100%; margin-top: 50px">
                          <tr>
                            <td>
                                Prepared by: 
                                <div class="font-weight-bold mt-4">{{ getPreparedBy($store.state.user.personnel_id)}}</div>
                                <div>{{getPreparedPosition($store.state.user.personnel_id)}}</div>
                            </td>
                            <td v-if="$store.state.user.personnel_id != 19 && $store.state.user.personnel_id != 15 && $store.state.user.personnel_id != 52 && $store.state.user.personnel_id != 27 && $store.state.user.personnel_id != 21 && $store.state.user.personnel_id != 38 && $store.state.user.personnel_id != 57 && $store.state.user.personnel_id != 67">
                                 Noted by: 
                                  <div class="font-weight-bold mt-4">{{getNoted($store.state.user.personnel_id)}}</div>
                                  <div>{{getNotedPosition($store.state.user.personnel_id)}}</div>
                            </td>
                          </tr>
                          <tr><td></td></tr><tr><td></td></tr><tr><td></td></tr><tr><td></td></tr><tr><td></td></tr><tr><td></td></tr><tr><td></td></tr>
                          <tr><td></td></tr><tr><td></td></tr><tr><td></td></tr><tr><td></td></tr><tr><td></td></tr><tr><td></td></tr><tr><td></td></tr>

                          <tr v-if="$store.state.user.role === 'COS' || $store.state.user.role === 'Records' || $store.state.user.role === 'Administrator'">
                            <td v-if="$store.state.user.personnel_id !== 67 || $store.state.user.personnel_id !== 57">
                                  Reviewed<span v-if="$store.state.user.personnel_id === 51">/Noted</span> by: 
                                  <div class="font-weight-bold mt-4">{{getReviewed($store.state.user.personnel_id)}}</div>
                                  <div>{{getReviewedPosition($store.state.user.personnel_id)}}</div>
                            </td>
                          </tr>
                        </table>
                        <!-- <div >
                            <div class="row mt-4">
                              <div class="form-group col-md-6">
                                Prepared by: 
                                <div class="font-weight-bold mt-4">{{ getPreparedBy($store.state.user.personnel_id)}}</div>
                                <div>{{getPreparedPosition($store.state.user.personnel_id)}}</div>
                              </div>
                              <div class="form-group col-md-6">
                                  Reviewed<span v-if="$store.state.user.personnel_id === 51">/Noted</span> by: 
                                  <div class="font-weight-bold mt-4">{{getReviewed($store.state.user.personnel_id)}}</div>
                                  <div>{{getReviewedPosition($store.state.user.personnel_id)}}</div>

                                  <div class="font-weight-bold mt-4" v-if="$store.state.user.personnel_id === 57 && $store.state.user.role === 'COS'">NOEME ROSE D. MANI, CPA, MM</div>
                                  <div v-if="$store.state.user.personnel_id === 57 && $store.state.user.role === 'COS'">Supervising Education Program Specialist</div>
                                </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-6">
                          
                              </div>
                              <div class="form-group col-md-6 mt-4" v-if="$store.state.user.role === 'COS'">
                                  Noted by: 
                                  <div class="font-weight-bold mt-4">{{getNoted($store.state.user.personnel_id)}}</div>
                                  <div>{{getNotedPosition($store.state.user.personnel_id)}}</div>
                                </div>
                            </div>
                        </div> -->
                      </div>
                  </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!------------ Start- Add Accomplishment Modal ------------>
    <div id="add-accomplishment" class="modal fade container-fluid">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Accomplishment</h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div class="modal-body">
                <div style="width:200px; font-size: 12px" class="mb-4">
                    <label class="font-weight-bold"><span class="text-danger">*</span>Date of Work</label>
                    <input type="date" v-model="date_work" class="form-control form-control-sm"/>
                </div>
              <div class="mb-3">
                <table class="table table-sm " style="font-size: 12px">
                  <thead class="text-center">
                    <th>#</th>
                    <th><span class="text-danger">*</span>Working Arrangement</th>
                    <th><span class="text-danger">*</span>Program</th>
                    <th><span class="text-danger">*</span>OPCR Indicator</th>
                    <th><span class="text-danger">*</span>SUB Indicator</th>
                    <th><span class="text-danger">*</span>IPCR Indicator</th>
                    <th><span class="text-danger">*</span>Type of Transaction</th>
                    <th><span class="text-danger">*</span>Origin</th>
                    <!-- <th>Date Received</th>
                    <th>Due Date</th>
                    <th>Date Accomplished</th> -->
                    <th><span class="text-danger">*</span>Details</th>
                    <th><span class="text-danger">*</span>Action Taken/Fwd</th>
                    <!-- <th>Remarks</th> -->
                  </thead>
                  <tbody>
                    <tr v-for="(ar, index) in arData" :key="index">
                      <th>{{ index + 1 }}</th>
                      <td style="width: 150px">
                        <select v-model="ar.ar_nature_awa" class="form-control form-control-sm" required>
                            <option 
                              v-for="(option, i) in get_awa"
                              :key="i"
                              :value="option.ar_nature_of_awa_id"
                            >{{option.awa_text}}</option>
                          </select>
                      </td>
                      <td style="width: 150px">
                        <select v-model="ar.ar_program" class="form-control form-control-sm" @change="checkProgram(ar.ar_program, index)" required>
                            <option 
                              v-for="(option, i) in get_programs"
                              :key="i"
                              :value="option.value"
                            >{{option.text}}</option>
                          </select>
                      </td>
                      <td style="width: 150px">
                          <select v-model="ar.ar_opcr" class="form-control form-control-sm" @change="checkOPCR(ar.ar_opcr, index)" required>
                            <option 
                              v-for="(option, i) in get_opcrs[index]"
                              :key="i"
                              :value="option.value"
                            >{{option.text}}</option>
                          </select>
                      </td>
                      <td style="width: 150px">
                          <select v-model="ar.ar_opcr_sub" class="form-control form-control-sm" required>
                            <option 
                              v-for="(option, i) in get_opcrs_subs[index]"
                              :key="i"
                              :value="option.value"
                            >{{option.text}}</option>
                          </select>
                      </td>
                      <td style="width: 150px">
                          <select v-model="ar.ar_ipcr_indicator" class="form-control form-control-sm" required>
                            <option 
                              v-for="(option, i) in get_ipcr"
                              :key="i"
                              :value="option.ar_ipcr_indicator_id"
                            >{{option.ipcr_text}}</option>
                          </select>
                      </td>
                      <td style="width: 150px">
                          <select v-model="ar.ar_type_transaction" class="form-control form-control-sm" required>
                            <option 
                              v-for="(option, i) in get_transaction"
                              :key="i"
                              :value="option.ar_type_of_transaction_id"
                            >{{option.transaction_text}}</option>
                          </select>
                      </td>
                      <td style="width: 150px">
                          <input type="text" v-model="ar.ar_origin" class="form-control form-control-sm" required/>
                      </td>
                      <!-- <td style="width: 100px">
                          <input type="date" v-model="ar.ar_date_requested" class="form-control form-control-sm"/>
                      </td>
                      <td style="width: 100px">
                        <input type="date" v-model="ar.ar_due_date" class="form-control form-control-sm"/> 
                      </td>
                      <td style="width: 100px">
                        <input type="date" v-model="ar.ar_date_accomplished" class="form-control form-control-sm"/>
                      </td> -->
                      <td>
                        <textarea type="text" v-model="ar.ar_action_taken" class="form-control form-control-sm" required/>
                      </td>
                      <td>
                          <textarea type="text" v-model="ar.ar_details" class="form-control form-control-sm" required/>
                      </td>
                      <!-- <td>
                        <textarea type="text" v-model="ar.ar_remarks" class="form-control form-control-sm"/>
                      </td> -->

                    </tr>
                  </tbody>
                </table>

                <div class="btn-group">
                  <button
                    class="btn btn-outline-secondary btn-sm"
                    @click="addARData"
                  >
                    <i class="bi bi-plus  icon-sm "></i>
                  </button>
                  <button
                    class="btn btn-outline-secondary btn-sm"
                    @click="removeARData"
                  >
                    <i class="bi bi-dash  icon-sm"></i>
                  </button>
                </div>

                </div>
            </div>
            <!-- END OF MODAL-BODY -->
            <div class="modal-footer">
              <span class="container" style="font-size:12px">Note: Fields marked with an asterisk (<span style="color:red">*</span>) are required.</span>   
              <button class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button class="btn btn-primary" @click="submitAccomplishmentReport" v-if="!loading">Save</button>
              <button class="btn btn-primary" type="button" disabled v-if="loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Saving...
              </button>
            </div>
        </div>
      </div>
    </div>
    <!------------ End - Add Accomplishment Modal ------------>
    <!------------ Start- Edit Accomplishment Modal ------------>
    <div id="edit-accomplishment" class="modal fade" >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Accomplishment</h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="submitUpdateAccomplishmentReport">
          <div class="modal-body">
                <div style="width:200px; font-size: 12px" class="mb-4">
                    <label class="font-weight-bold"><span class="text-danger">*</span>Date of Work</label>
                    <input type="date" v-model="edit_ar.date_of_work" class="form-control form-control-sm"/>
                </div>
              <div class="mb-3">
                <table class="table table-sm " style="font-size: 12px">
                  <thead class="text-center">
                    <th>#</th>
                    <th><span class="text-danger">*</span>Working Arrangement</th>
                    <th><span class="text-danger">*</span>Program</th>
                    <th><span class="text-danger">*</span>OPCR Indicator</th>
                    <th><span class="text-danger">*</span>SUB Indicator</th>
                    <th><span class="text-danger">*</span>IPCR Indicator</th>
                    <th><span class="text-danger">*</span>Type of Transaction</th>
                    <th><span class="text-danger">*</span>Origin</th>
                    <!-- <th>Date Received</th>
                    <th>Due Date</th>
                    <th>Date Accomplished</th> -->
                    <th><span class="text-danger">*</span>Details</th>
                    <th>Action Taken/Fwd</th>
                  </thead>
                  <tbody>
                    <tr>
                      <th>1</th>
                      <td style="width: 150px">
                        <select v-model="edit_ar.nature_of_awa" class="form-control form-control-sm" required>
                            <option 
                              v-for="(option, i) in get_awa"
                              :key="i"
                              :value="option.ar_nature_of_awa_id"
                            >{{option.awa_text}}</option>
                          </select>
                      </td>
                      <td style="width: 150px">
                        <select v-model="edit_ar.ar_program" class="form-control form-control-sm" @change="checkProgram(edit_ar.ar_program)" required>
                            <option 
                              v-for="(option, i) in get_programs"
                              :key="i"
                              :value="option.value"
                            >{{option.text}}</option>
                          </select>
                      </td>
                      <td style="width: 150px">
                          <select v-model="edit_ar.ar_opcr" class="form-control form-control-sm" @change="checkOPCR(edit_ar.ar_opcr)" required>
                            <option 
                              v-for="(option, i) in get_opcrs"
                              :key="i"
                              :value="option.value"
                            >{{option.text}}</option>
                          </select>
                      </td>
                      <td style="width: 150px">
                          <select v-model="edit_ar.ar_opcr_sub" class="form-control form-control-sm" required>
                            <option 
                              v-for="(option, i) in get_opcrs_subs"
                              :key="i"
                              :value="option.value"
                            >{{option.text}}</option>
                          </select>
                      </td>
                      <td style="width: 150px">
                          <select v-model="edit_ar.ipcr_indicator" class="form-control form-control-sm" required>
                            <option 
                              v-for="(option, i) in get_ipcr"
                              :key="i"
                              :value="option.ar_ipcr_indicator_id"
                            >{{option.ipcr_text}}</option>
                          </select>
                      </td>
                      <td style="width: 150px">
                          <select v-model="edit_ar.type_of_transaction" class="form-control form-control-sm" required>
                            <option 
                              v-for="(option, i) in get_transaction"
                              :key="i"
                              :value="option.ar_type_of_transaction_id"
                            >{{option.transaction_text}}</option>
                          </select>
                      </td>
                      <td style="width: 150px">
                          <input type="text" v-model="edit_ar.origin" class="form-control form-control-sm" required/>
                      </td>
                      <!-- <td style="width: 100px">
                          <input type="date" v-model="edit_ar.date_requested" class="form-control form-control-sm"/>
                      </td>
                      <td style="width: 100px">
                        <input type="date" v-model="edit_ar.due_date" class="form-control form-control-sm"/> 
                      </td>
                      <td style="width: 100px">
                        <input type="date" v-model="edit_ar.accomplished_date" class="form-control form-control-sm"/>
                      </td> -->
                      <td>
                        <textarea type="text" v-model="edit_ar.action_taken" class="form-control form-control-sm"/>
                      </td>
                      <td>
                          <textarea type="text" v-model="edit_ar.details" class="form-control form-control-sm" required/>
                      </td>
                    </tr>
                  </tbody>
                </table>
                </div>
            </div>
            <!-- END OF MODAL-BODY -->
            <div class="modal-footer">
              <span class="container" style="font-size:12px">Note: Fields marked with an asterisk (<span style="color:red">*</span>) are required.</span>   
              <button class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button class="btn btn-primary" type="submit" v-if="!loading">Update</button>
              <button class="btn btn-primary" type="button" disabled v-if="loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Updating...
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!------------ End - Edit Accomplishment Modal ------------>



  </div>
</template>
<style scoped>
  .modal-content {
    width: 120%;
    margin-left: -150px;
  }
</style>
<script>
import VueHtmlToPaper from 'vue-html-to-paper';
import Vue from 'vue'
import AccomplishmentReport from './components/AccomplishmentReportTable.vue'
import NatureWork from './components/NatureWorkTable.vue'
import IpcrIndicator from './components/IPCRIndicatorTable.vue'
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
  ]
}
Vue.use(VueHtmlToPaper, options);

export default {
  components: { AccomplishmentReport, NatureWork, IpcrIndicator },
  data() {
    return {
      preview: false,
      printPreview: false,
      unpreview: false,
      globalIndex: 0,
      globalIndexx: 0,
      globalIndex1: '',
      globalIndex2: '',
      globalVal2: '',
      globalVal: '',
      from: "",
      to: "",
      editDate: {
        accomplishment_date: ''
      },
      editActivities: {
        accomplishment: ''
      },
      editPlan: {
        plan: '',
        from_date_plan: '',
        to_date_plan: ''
      },
      loading: false,
      loading1: false,
      date_work: '',
      filter: false,
      ar: {
        ar_ipcr_indicator: '',
        ar_opcr: '',
        ar_opcr_sub: ''
      },
      arData: [
      {
          ar_details: '',
          ar_nature_awa: '1',
          ar_type_transaction: '',
          ar_ipcr_indicator: '',
          ar_origin: 'CHED Caraga',
          ar_date_requested: '',
          ar_due_date: '',
          ar_date_acted: '',
          ar_action_taken: '',
          ar_date_accomplished: '',
          ar_remarks: '',
          ar_program: '',
          ar_opcr: '',
          ar_opcr_sub: ''
        }
      ],
      edit_ar: {
        ar_opcr: '',
        ar_opcr_sub: '',
        ar_program: '',
        date_of_work: '',
        details: '',
        ar_nature_of_awa_id: '',
        ar_nature_of_work_id: '',
        ar_type_of_transaction_id: '',
        ar_ipcr_indicator_id: '',
        origin: '',
        date_requested: '',
        due_date: '',
        action_taken: '',
        accomplished_date: '',
        remarks: ''
      },
      addAWA: true,
      addWork: true,
      addIPCR: true,
      addTransaction: true,
      add_awa: '',
      add_work: '',
      add_ipcr: '',
      add_transaction: '',
      edit_work: {
        work_text: '',
      },
      edit_ipcr: {
        text: '',
      },
      ar_plan: {
        plan: '',
        from_date_plan: '',
        to_date_plan: ''
      },
      accomplishment_reportss: [],
      get_plans: [],
      get_work: [],
      get_programs: [
        {text: 'HIGHER EDUCATION DEVELOPMENT PROGRAM', value: 1},
        {text: 'HIGHER EDUCATION REGULATION PROGRAM', value: 2},
      ],
      get_opcrs: [],
      get_opcrs_subs: [],
      get_awa: [],
      get_ipcr: [],
      get_transaction: [],
      get_accomplishment_reports: [],
      get_accomplishments: [],
      get_accomplishment_dates: [],
      regularEmployees: [],
      chedEmployees: [
        { BiometricID: '8', FirstName: 'Kathleen', LastName: 'Castanares', prepared: 'KATHLEEN D. CASTAÑARES', p_position: 'Project Technical Staff I - Budget Assistant', reviewed: 'ALMA S. PATRON, MSW, LLB', r_position: 'Chief Administrative Officer', noted: 'ALMA S. PATRON, MSW, LLB', n_position: 'Chief Administrative Officer'},
        { BiometricID: '9', FirstName: 'Sarah', LastName: 'Moron', prepared: 'SARAH T. MORON', p_position: 'Project Technical Staff I, UniFAST/Accounting Staff', reviewed: 'GESELLE M. FRANCISCO, CPA', r_position: 'Accountant II', noted: 'ALMA SY-PATRON, LLB, MSW', n_position: 'Chief Administrative Officer'},
        { BiometricID: '14', FirstName: 'Calwin', LastName: 'Alegre', prepared: 'JOBERT CALWIN M. ALEGRE', p_position: 'Project Technical Staff III - MIS', reviewed: 'AMY C. SAAVEDRA, MSCA', r_position: 'Education Supervisor II - MIS Head', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '15', FirstName: 'Daisy', LastName: 'Grana', prepared: 'DAISY I. GRANA', p_position: 'Project Technical Staff II, UniFAST', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist', noted: 'GEORGE M. COLORADO, PHD, CESO III', n_position: 'Director IV'},
        { BiometricID: '16', FirstName: 'May Trinity', LastName: 'Narca', prepared: 'MAY TRINITY N. DE LA TORRE', p_position: 'Project Technical Staff II, UniFAST/RD Secretary', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist', noted: 'ALMA S. PATRON, JD, MSW, RSW', n_position: 'OIC - Director IV'},
        { BiometricID: '17', FirstName: 'Alexis Dave', LastName: 'Sanmiguel', prepared: 'ALEXIS DAVE M. SAN MIGUEL', p_position: 'Project Technical Staff I', reviewed: 'CRISELDO C. CALINAWAN, PHD, PECE', r_position: 'Education Supervisor II', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '18', FirstName: 'Krizia Meijel', LastName: 'Mellijor', prepared: 'KRIZIA MEIJEL MELLIJOR', p_position: 'Project Technical Staff II', reviewed: 'GESELLE M. FRANCISCO, CPA', r_position: 'Accountant II', noted: 'ALMA SY-PATRON, LLB, MSW', n_position: 'Chief Administrative Officer'},
        { BiometricID: '19', FirstName: 'Valerie', LastName: 'Vismanos', prepared: 'VALERIE EDFLO B. VISMANOS', p_position: 'Project Technical Staff I - UAQTE', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist', noted: '', n_position: ''},
        { BiometricID: '21', FirstName: 'Heidi', LastName: 'Paradero', prepared: 'HEIDI M. PARADERO', p_position: 'Project Technical Staff II', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist', noted: '', n_position: ''},
        { BiometricID: '22', FirstName: 'Jo', LastName: 'Orendain', prepared: 'JO SHEILA ORENDAIN', p_position: 'Project Technical Staff I', reviewed: 'REVELYN P. BRINA, MAED', r_position: 'Administrative Officer III', noted: 'ALMA SY-PATRON, LLB, MSW', n_position: 'Chief Administrative Officer'},
        { BiometricID: '23', FirstName: 'Eleazar', LastName: 'Sumalinog', prepared: 'ELEAZAR A. SUMALINOG', p_position: '', reviewed: '', r_position: '', noted: '', n_position: ''},
        { BiometricID: '30', FirstName: 'Ronan', LastName: 'Otaza', prepared: 'RONAN E. OTAZA', p_position: 'Project Technical Staff I - CSP', reviewed: 'NOEME ROSE D. MANI, CPA, MM', r_position: 'Supervising Education Program Specialist', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '38', FirstName: 'Bill', LastName: 'Baconguis', prepared: 'BILL CARLO R. BACONGUIS', p_position: 'Project Technical Staff II, UniFAST/MIS', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist', noted: 'GEORGE M. COLORADO, PHD, CESO III', n_position: 'Director IV'},
        { BiometricID: '39', FirstName: 'Christine', LastName: 'Escol', prepared: 'CHRISTINE R. ESCOL', p_position: 'CSP Support Staff', reviewed: 'NOEME ROSE D. MANI, CPA, MM', r_position: 'Supervising Education Program Specialist', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '42', FirstName: 'Charles Jem', LastName: 'Montes', prepared: 'CHARLES JEM M. MONTES', p_position: 'Procurement/Admin Staff', reviewed: 'ANGEL A. ASIS, MM-CAR', r_position: 'Administrative Aide VI/HR', noted: 'ALMA SY-PATRON, LLB, MSW', n_position: 'Chief Administrative Officer'},
        { BiometricID: '43', FirstName: 'Trician Faye', LastName: 'Gozon', prepared: 'TRICIAN FAYE D. GOZON', p_position: 'Admin Staff', reviewed: 'REVELYN P. BRINA, MAED', r_position: 'Administrative Officer III', noted: 'GESELLE M. FRANCISCO, CPA', n_position: 'OIC - Chief Administrative Officer'},
        { BiometricID: '46', FirstName: 'Maria Mercedes', LastName: 'Rebita', prepared: 'MA. MERCEDES P. REBITA', p_position: 'Project Technical Staff I - Records Section', reviewed: 'REVELYN P. BRINA, MAED', r_position: 'Administrative Officer III', noted: 'GESELLE M. FRANCISCO, CPA', n_position: 'OIC - Chief Administrative Officer'},
        { BiometricID: '51', FirstName: 'Gladys', LastName: 'Concha', prepared: 'GLADYS O. CONCHA', p_position: 'CHED-COA Support Staff', reviewed: 'JONIFEL T. SAN JUAN, CPA', r_position: 'State Auditor III', noted: 'JONIFEL T. SAN JUAN, CPA', n_position: 'State Auditor III'},
        { BiometricID: '52', FirstName: 'Kershey Reign', LastName: 'Dueñas', prepared: 'KERSHEY REIGN E. DUEÑAS', p_position: 'Project Technical Staff II, UniFAST', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist', noted: '', n_position: ''},
        { BiometricID: '53', FirstName: 'Mona Pia', LastName: 'Galing', prepared: 'MONA PIA G. GALING', p_position: 'Project Technical Staff I - HRDS', reviewed: 'ANGEL A. ASIS, MM-CAR', r_position: 'Administrative Aide VI/HR', noted: 'ALMA S. PATRON, MSW, LLB', n_position: 'Chief Administrative Officer'},
        { BiometricID: '55', FirstName: 'Christy Joy', LastName: 'Lajos', prepared: 'CHRISTY JOY E. LAJOS', p_position: 'Cashier Support Staff', reviewed: 'FRANCIS D. ILAGAN', r_position: 'Administrative Officer III', noted: 'ALMA SY-PATRON, MSW, LLB', n_position: 'Chief Administrative Officer'},
        { BiometricID: '57', FirstName: 'Orpheus', LastName: 'Cubillas', prepared: 'ORPHEUS A. CUBILLAS', p_position: 'Project Technical Staff II - Sports Coordinator', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '62', FirstName: 'Maelove', LastName: 'Dagohoy', prepared: 'MAELOVE I. DAGOHOY', p_position: 'Project Technical Staff III -  Accounting Unit', reviewed: 'GESELLE M. FRANCISCO, CPA', r_position: 'Accountant II', noted: 'ALMA S. PATRON, MSW, LLB', n_position: 'Chief Administrative Officer'},
        { BiometricID: '63', FirstName: 'JESIELYN MAE', LastName: 'ADO', prepared: 'JESIELYN MAE I. ADO', p_position: 'Project Technical Staff II -  Regional Statistical Focal Officer', reviewed: 'AMY C. SAAVEDRA, MSCA', r_position: 'Education Supervisor II - MIS Head', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '65', FirstName: 'Eljean Rose', LastName: 'Sande', prepared: 'ELJEAN ROSE U. SANDE', p_position: 'CSP Support Staff', reviewed: 'NOEME ROSE D. MANI, CPA, MM', r_position: 'Supervising Education Program Specialist', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '66', FirstName: 'Ceaka Angel', LastName: 'Pacot', prepared: 'CEAKA ANGEL P. PACOT', p_position: 'Admin Staff', reviewed: 'REVELYN P. BRINA, MAED', r_position: 'Administrative Officer III', noted: 'GESELLE M. FRANCISCO, CPA', n_position: 'OIC - Chief Administrative Officer'},
        { BiometricID: '67', FirstName: 'Christopher', LastName: 'Hingpit', prepared: 'CHRISTOPHER A. HINGPIT', p_position: 'Project Technical Staff III', reviewed: '', r_position: '', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        { BiometricID: '68', FirstName: 'Marti Jose', LastName: 'Maban', prepared: 'MARTI JOSE M. MABAN', p_position: 'Project Technical Staff II', reviewed: 'CRISELDO C. CALINAWAN,PHD, PECE', r_position: 'Education Supervisor II', noted: 'JULIA FELISA C. MARTINEZ, PHD, LPT', n_position: 'Chief Education Program Specialist'},
        //REGULAR  EMPLOYEES
        { BiometricID: '1', FirstName: 'Alma', LastName: 'Patron', prepared: 'ALMA S. PATRON, MSW, LLB', p_position: 'Chief Administrative Officer', reviewed: 'GEORGE M. COLORADO, PHD, CESO III', r_position: 'Director IV'},
        { BiometricID: '2', FirstName: 'Geselle', LastName: 'Francisco', prepared: 'GESELLE G. FRANCISCO, CPA', p_position: 'Accountant II', reviewed: 'ALMA SY-PATRON, LLB, MSW', r_position: 'Chief Administrative Officer'},
        { BiometricID: '3', FirstName: 'Francis', LastName: 'Ilagan', prepared: 'FRANCIS D. ILAGAN', p_position: 'Administrative Officer III', reviewed: 'ALMA SY-PATRON, LLB, MSW', r_position: 'Chief Administrative Officer'},
        { BiometricID: '4', FirstName: 'Haemaccel', LastName: 'Palima', prepared: 'HAEMACEL I. PALIMA', p_position: 'Admin Aide III/Supply Officer', reviewed: 'ALMA SY-PATRON, LLB, MSW', r_position: 'Chief Administrative Officer'},
        { BiometricID: '5', FirstName: 'Misael', LastName: 'Cabang', prepared: 'MISAEL S. CABANG', p_position: 'Admin Aide III', reviewed: 'ALMA SY-PATRON, LLB, MSW', r_position: 'Chief Administrative Officer'},
        { BiometricID: '13', FirstName: 'Julia Felisa', LastName: 'Martinez', prepared: 'JULIA FELISA C. MARTINEZ, PHD, LPT', p_position: 'Chief Education Program Specialist', reviewed: 'GEORGE M. COLORADO, PHD, CESO III', r_position: 'Director IV', noted: 'GEORGE M. COLORADO, PHD, CESO III', n_position: 'Director IV'},
        { BiometricID: '26', FirstName: 'Angel', LastName: 'Asis', prepared: 'ANGEL A. ASIS,MM-CAR', p_position: 'Administrative Aide VI/HR', reviewed: 'ALMA SY-PATRON, LLB, MSW', r_position: 'Chief Administrative Officer'},
        { BiometricID: '28', FirstName: 'Revelyn', LastName: 'Brina', prepared: 'REVELYN P. BRINA, MAED', p_position: 'Administrative Officer III', reviewed: 'ALMA SY-PATRON, LLB, MSW', r_position: 'Chief Administrative Officer'},
        { BiometricID: '59', FirstName: 'Noeme', LastName: 'Mani', prepared: 'NOEME ROSE D. MANI, CPA, MM', p_position: 'Supervising Education Program Specialist', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist'},
        { BiometricID: '32', FirstName: 'Criseldo', LastName: 'Calinawan', prepared: 'ENGR. CRISELDO C. CALINAWAN, PHD', p_position: 'Education Supervisor II', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist'},
        { BiometricID: '34', FirstName: 'Amy', LastName: 'Saavedra', prepared: 'AMY C. SAAVEDRA, MSCA', p_position: 'Education Supervisor II', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist'},
        { BiometricID: '36', FirstName: 'Fred', LastName: 'Amihan', prepared: 'FREDERICK C. AMIHAN, PHD', p_position: 'Education Supervisor II', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist'},
        { BiometricID: '37', FirstName: 'Rolyna', LastName: 'Trugillo', prepared: 'ROLYNA S. TRUGILLO, PHD', p_position: 'Education Supervisor II', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist'},
        { BiometricID: '61', FirstName: 'Ever Joy', LastName: 'Rabadan', prepared: 'EVER JOY G. RABADAN, MAEM, MSc.', p_position: 'Education Supervisor II', reviewed: 'JULIA FELISA C. MARTINEZ, PHD, LPT', r_position: 'Chief Education Program Specialist'},

      ],
      chedEmployeesUnit: [ 
        { BiometricID: '6', FirstName: 'Neil Mart Von', LastName: 'Ronquillo', division: 'ADMINISTRATIVE', unit_section: 'ACCOUNTING'},
        { BiometricID: '7', FirstName: 'Mary', LastName: 'Vallejos', division: 'ADMINISTRATIVE', unit_section: 'ACCOUNTING'},
        { BiometricID: '8', FirstName: 'Kathleen', LastName: 'Castanares', division: 'ADMINISTRATIVE', unit_section: 'BUDGET'},
        { BiometricID: '9', FirstName: 'Sarah', LastName: 'Moron', division: 'ADMINISTRATIVE', unit_section: 'ACCOUNTING'},
        { BiometricID: '11', FirstName: 'Judith', LastName: 'Makidato', division: 'ADMINISTRATIVE', unit_section: 'RECORDS'},
        { BiometricID: '14', FirstName: 'Calwin', LastName: 'Alegre', division: 'TECHNICAL', unit_section: 'MIS'},
        { BiometricID: '15', FirstName: 'Daisy', LastName: 'Grana', division: 'TECHNICAL', unit_section: 'UniFAST'},
        { BiometricID: '16', FirstName: 'May Trinity', LastName: 'Narca', division: 'TECHNICAL', unit_section: 'UniFAST'},
        { BiometricID: '17', FirstName: 'Alexis Dave', LastName: 'Sanmiguel', division: 'TECHNICAL', unit_section: 'MARITIME'},
        { BiometricID: '18', FirstName: 'Krizia Meijel', LastName: 'Mellijor', division: 'ADMINISTRATIVE', unit_section: 'ACCOUNTING'},
        { BiometricID: '19', FirstName: 'Valerie', LastName: 'Vismanos', division: 'TECHNICAL', unit_section: 'UniFAST'},
        { BiometricID: '21', FirstName: 'Heidi', LastName: 'Paradero', division: 'TECHNICAL', unit_section: 'UniFAST'},
        { BiometricID: '22', FirstName: 'Jo', LastName: 'Orendain', division: 'ADMINISTRATIVE', unit_section: 'FRONT-DESK'},
        { BiometricID: '23', FirstName: 'Eleazar', LastName: 'Sumalinog', division: 'ADMINISTRATIVE', unit_section: 'GENERAL SERVICES'},
        { BiometricID: '27', FirstName: 'Wenna', LastName: 'Damulo', division: 'TECHNICAL', unit_section: 'LGSO'},
        { BiometricID: '30', FirstName: 'Ronan', LastName: 'Otaza', division: 'TECHNICAL', unit_section: 'CSP'},
        { BiometricID: '38', FirstName: 'Bill', LastName: 'Baconguis', division: 'TECHNICAL', unit_section: 'MIS'},
        { BiometricID: '39', FirstName: 'Christine', LastName: 'Escol', division: 'TECHNICAL', unit_section: 'CSP'},
        { BiometricID: '40', FirstName: 'Shiena', LastName: 'Balios', division: 'TECHNICAL', unit_section: 'CSP'},
        { BiometricID: '41', FirstName: 'Rose Antoniet', LastName: 'Jamero', division: 'TECHNICAL', unit_section: 'CSP'},
        { BiometricID: '42', FirstName: 'Charles Jem', LastName: 'Montes', division: 'ADMINISTRATIVE', unit_section: 'PROCUREMENT'},
        { BiometricID: '43', FirstName: 'Trician Faye', LastName: 'Gozon', division: 'ADMINISTRATIVE', unit_section: 'RECORDS'},
        { BiometricID: '44', FirstName: 'Jenver', LastName: 'Canlas', division: 'ADMINISTRATIVE', unit_section: 'PROCUREMENT'},
        { BiometricID: '46', FirstName: 'Maria Mercedes', LastName: 'Rebita', division: 'ADMINISTRATIVE', unit_section: 'RECORDS'},
        { BiometricID: '47', FirstName: 'Rowena', LastName: 'Japson', division: 'ADMINISTRATIVE', unit_section: 'RECORDS'},
        { BiometricID: '48', FirstName: 'Michelle', LastName: 'Caingles', division: 'ADMINISTRATIVE', unit_section: 'RECORDS'},
        { BiometricID: '49', FirstName: 'Resa', LastName: 'Donayre', division: 'TECHNICAL', unit_section: 'CSP'},
        { BiometricID: '50', FirstName: 'Cynthia', LastName: 'Magalop', division: 'ADMINISTRATIVE', unit_section: 'ACCOUNTING'},
        { BiometricID: '51', FirstName: 'Gladys', LastName: 'Concha', division: 'ADMINISTRATIVE', unit_section: 'CHED-COA'},
        { BiometricID: '52', FirstName: 'Kershey Reign', LastName: 'Dueñas', division: 'TECHNICAL', unit_section: 'UniFAST'},
        { BiometricID: '53', FirstName: 'Mona Pia', LastName: 'Galing', division: 'ADMINISTRATIVE', unit_section: 'HR'},
        { BiometricID: '54', FirstName: 'Jan Myrtle', LastName: 'Villanueva', division: 'TECHNICAL', unit_section: 'MIS'},
        { BiometricID: '55', FirstName: 'Christy Joy', LastName: 'Lajos', division: 'ADMINISTRATIVE', unit_section: 'CASHIER SECTION'},
        { BiometricID: '56', FirstName: 'Junlou', LastName: 'Tordos', division: 'ADMINISTATIVE', unit_section: 'HR'},
        { BiometricID: '57', FirstName: 'Orpheus', LastName: 'Cubillas', division: 'TECHNICAL', unit_section: 'CHED SPORTS UNIT'},
        { BiometricID: '58', FirstName: 'Felgen Mark', LastName: 'Gabin', division: 'ADMINISTRATIVE', unit_section: 'PROCUREMENT'},
        { BiometricID: '65', FirstName: 'Eljean Rose', LastName: 'Sande', division: 'TECHNICAL', unit_section: 'CSP'},
        { BiometricID: '63', FirstName: 'Jesielyn Mae', LastName: 'Ado', division: 'TECHNICAL', unit_section: 'CSP'},
        { BiometricID: '67', FirstName: 'Christopher', LastName: 'Hingpit', division: 'TECHNICAL', unit_section: 'LGSO'},
        { BiometricID: '68', FirstName: 'Marti Jose', LastName: 'Maban', division: 'TECHNICAL', unit_section: 'MARITIME'},


        //REGULAR EMPLOYEE
        { BiometricID: '1', FirstName: 'Alma', LastName: 'Patron', division: 'ADMINISTRATIVE', unit_section: 'ADMIN'},
        { BiometricID: '2', FirstName: 'Geselle', LastName: 'Francisco', division: 'ADMINISTRATIVE', unit_section: 'ACCOUNTING'},
        { BiometricID: '3', FirstName: 'Francis', LastName: 'Ilagan', division: 'ADMINISTRATIVE', unit_section: 'HR'},
        { BiometricID: '4', FirstName: 'Haemaccel', LastName: 'Palima', division: 'ADMINISTRATIVE', unit_section: 'PROCUREMENT'},
        { BiometricID: '5', FirstName: 'Misael', LastName: 'Cabang', division: 'ADMINISTRATIVE', unit_section: 'PROCUREMENT'},
        { BiometricID: '13', FirstName: 'Julia Felisa', LastName: 'Martinez', division: 'TECHNICAL', unit_section: 'MONITORING AND EVALUATION'},
        { BiometricID: '26', FirstName: 'Angel', LastName: 'Asis', division: 'ADMINISTRATIVE', unit_section: 'BUDGET'},
        { BiometricID: '28', FirstName: 'Revelyn', LastName: 'Brina', division: 'ADMINISTRATIVE', unit_section: 'RECORDS'},
        { BiometricID: '59', FirstName: 'Noeme', LastName: 'Mani', division: 'TECHNICAL', unit_section: 'MPSD'},
        { BiometricID: '32', FirstName: 'Criseldo', LastName: 'Calinawan', division: 'TECHNICAL', unit_section: 'MPSD'},
        { BiometricID: '34', FirstName: 'Amy', LastName: 'Saavedra', division: 'TECHNICAL', unit_section: 'MIS'},
        { BiometricID: '36', FirstName: 'Fred', LastName: 'Amihan', division: 'TECHNICAL', unit_section: 'MPSD'},
        { BiometricID: '37', FirstName: 'Rolyna', LastName: 'Trugillo', division: 'TECHNICAL', unit_section: 'MPSD'},
        { BiometricID: '61', FirstName: 'Ever Joy', LastName: 'Rabadan', division: 'TECHNICAL', unit_section: 'MPSD'},

      ],
      total_filters: 0

    }
  },
  mounted() {
   this.getAccomplishmentReports();

   var support_date = new Date();
   support_date = moment(support_date).format("YYYY-MM-DD");

   this.date_work = support_date;
  },
  watch: {
    'globalIndex'(val) {
      console.log('from watch', this.globalVal2, val);
      if(this.globalVal == 1) {
        return this.get_opcrs[val] = [
          {text: 'Provision of assistance to HEIs for Local Graduate Studies', value: 1},
          {text: 'No. of activities conducted', value: 2},
          {text: 'Number of HEIs/stakeholders assisted/facilitated from the activities conducted as experts or resource persons:', value: 3},
          {text: 'Provision of assistance, incentives, scholarships and grants through CHED Scholarship Programs', value: 4},
          {text: 'Others', value: 35},
        ]
      }
      if(this.globalVal == 2) {
        return this.get_opcrs[val] = [
          {text: 'Development of Standards for higher education programs and institutions', value: 5},
          {text: 'Number of HEIs that applied for permits and recognitions', value: 6},
          {text: 'Number of programs subjected to standards (Initial/renewal permits/recognition processed/issued)', value: 7},
          {text: 'Number of curricula evaluated per PSG', value: 8},
          {text: 'Number of applications processed/acted on Tuition and Other Fees increases', value: 9},
          {text: 'Monitoring and evaluation of performance  of HE programs', value: 10},
          {text: 'Enforcement', value: 11},
          {text: 'Number of HEIs subjected to reform (eg merge, close program not in mandate, reorganize BOR, updated curricula)', value: 12},
          {text: '50% of SNPLP borrowers sent demand letters', value: 13},
          {text: 'SUPPORT TO OPERATIONS', value: 14},
          {text: 'Monitoring of HEI Services', value: 15},
          {text: 'No. of HEIs with Tuition Fee increase monitored', value: 16},
          {text: '"No. of HEIs monitored on Student Services XB(35% ) B (50%) M (65%) S(80%)"', value: 17},
          {text: 'No. of IEC materials developed/published (e.g. newsletter, reports, publication)', value: 18},
          {text: 'CHEDRO Frontline Services', value: 19},
          {text: '100% of CAVs, SOs and other certifications processed and acted', value: 20},
          {text: 'Mediation / Investigation / Conciliation', value: 21},
          {text: '100% of the cases acted upon', value: 22},
          {text: 'No. of procurement activities conducted - minimum of 1 per month (12 in a year)', value: 23},
          {text: 'Number of financial transactions: acted, processed and released', value: 24},
          {text: '100% of total communications received processed and acted', value: 25},
          {text: 'GENERAL ADMINISTRATION AND SUPPORT SERVICES', value: 26},
          {text: 'No. of Human Resource Development Activities - minimum of 2 ', value: 27},
          {text: '100% of total plantilla employees attended trainings/seminars/workshops', value: 28},
          {text: 'No. of office planning/assessment activities conducted/attended - minimum of 2', value: 29},
          {text: 'Budget Utilization (in thousand)', value: 30},
          {text: 'Regional GAA', value: 31},
          {text: 'Fund Transfer from DBM Caraga (101)', value: 32},
          {text: 'Fund Transfer from CHEDCO (101)', value: 33},
          {text: 'Fund Transfer (151)', value: 34},
          {text: 'Others', value: 35},

        ]
      }
    },
    'globalIndexx'(val) {
      if(this.globalVal2 == 1) {
        return this.get_opcrs_subs[val] = [
          {text: 'No. of faculty members provided with faculty development grants ', value: 1},
        ]
      }
      if(this.globalVal2 == 2 || this.globalVal2 == 3 || this.globalVal2 == 35) {
        return this.get_opcrs_subs[val] = [
          {text: 'Others', value: 36}, // opcrsubs[0] = [] //opcrsybs[1] = [] // opcsr 2 = []
        ];
      }
      if(this.globalVal2 == 4) {
        return this.get_opcrs_subs[val] = [
          {text: 'Number of CSP applications received and processed', value: 2},
          {text: 'Number of CSP slots awarded ', value: 3},
          {text: 'Number of  grantees who completed their degrees in CHED priority programs', value: 4},
          {text: 'Number of scholarship grantees completing their courses in AY 2022-2023', value: 5},
          {text: '% of payments received by grantee/HEI within 5 working days of the scheduled payment date', value: 6},
          {text: '"No of HEIs  with CSP monitored XB (221 and above-35%) B (50%) M (65%) S (80%)"', value: 7},

        ]
      }
      if(this.globalVal2 == 5) {
        return this.get_opcrs_subs[val] = [
          {text: 'Number of applications for permits (programs/HEIs) received and processed', value: 8},
        ]
      }

      if(this.globalVal2 == 6) {
        return this.get_opcrs_subs[val] = [
          {text: 'a. Initial Permit ', value: 9},
          {text: 'b.  Renewal Permits', value: 10},
          {text: 'c.  Government Recognition', value: 11},
          {text: 'd. COPC', value: 12},
          {text: 'Number of LUCs recommended for Certificate of Recognition', value: 13}
        ]
      }
      if(this.globalVal2 == 7) {
        return this.get_opcrs_subs[val] = [
          {text: 'a.  Initial Permit ', value: 14},
          {text: 'b.  Renewal Permits', value: 15},
          {text: 'c.  Government Recognition', value: 16},
          {text: 'd. COPC', value: 17},
        ]
      }
      if(this.globalVal2 == 8 || this.globalVal2 == 9) {
        return this.get_opcrs_subs[val] = [
          {text: 'Others', value: 36},
        ];
      }
      if(this.globalVal2 == 10) {
        return this.get_opcrs_subs[val] = [
          {text: 'Number of Programs  evaluated/monitored for PSG compliance  (5% of the total programs for BIG Regions and 7% for MEDIUM regions and 10% for SMALL regions.  B(151 above) M(81-150) S(80 below)', value: 18},
        ];
      }
      if(this.globalVal2 == 11 || this.globalVal2 == 12 || this.globalVal2 == 13 || this.globalVal2 == 14 || this.globalVal2 == 15 || this.globalVal2 == 16 || this.globalVal2 == 17) {
        return this.get_opcrs_subs[val] = [
          {text: 'Others', value: 36},
        ];
      }
      if(this.globalVal2 == 18) {
        return this.get_opcrs_subs[val] = [
          {text: 'a.  Annual Report (Executive Summary of the quarterly report)', value: 19},
          {text: 'b.  Statistical Bulletin', value: 20},
          {text: 'c.  Regional Website', value: 21},
        ]
      }
      if(this.globalVal2 == 19) {
        return this.get_opcrs_subs[val] = [
          {text: 'Others', value: 36},
        ];
      }
      if(this.globalVal2 == 20) {
        return this.get_opcrs_subs[val] = [
          {text: 'a. CAV', value: 22},
          {text: 'b.  S.O.', value: 23},
          {text: 'c.  Certification', value: 24},
          {text: '80% of total HEIs MIS data retrieved and validated', value: 25},
        ];
      }
      if(this.globalVal2 == 21) {
        return this.get_opcrs_subs[val] = [
            {text: 'Others', value: 36},
        ];
      }
      if(this.globalVal2 == 22) {
        return this.get_opcrs_subs[val] = [
          {text: 'a.  Mediation', value: 26},
          {text: 'b.  Investigation ', value: 27},
          {text: 'c.  Conciliation', value: 28},
        ];
      }
      if(this.globalVal2 == 23) {
        return this.get_opcrs_subs[val] = [
          {text: 'Others', value: 36},
        ]; 
      }
      if(this.globalVal2 == 24) {
        return this.get_opcrs_subs[val] = [
          {text: 'a. Vouchers Processed', value: 29},
          {text: 'b. Checks Issued', value: 30},
          {text: 'c. LDDAP-ADA issued', value: 31},
        ]; 
      }
      if(this.globalVal2 == 25) {
        return this.get_opcrs_subs[val] = [
          {text: '100% of reports prepared / submitted (to CHED, DBM, COA, CSC, HDMF, GSIS, Ombudsman, CESB, OP, etc.)', value: 32},
        ]; 
      }
      if(this.globalVal2 == 26 || this.globalVal2 == 27 || this.globalVal2 == 28 || this.globalVal2 == 29 || this.globalVal2 == 30) {
        return this.get_opcrs_subs[index] = [
          {text: 'Others', value: 36},
        ]; 
      }
      if(this.globalVal2 == 31) {
        return this.get_opcrs_subs[val] = [
          {text: 'Allotment)', value: 33},
          {text: 'Obligation', value: 34},
          {text: 'Disbursement ', value: 35},
        ]; 
      }
      if(this.globalVal2 == 32) {
        return this.get_opcrs_subs[val] = [
          {text: 'Allotment)', value: 33},
          {text: 'Obligation', value: 34},
          {text: 'Disbursement ', value: 35},
        ]; 
      }
      if(this.globalVal2 == 33) {
        return this.get_opcrs_subs[val] = [
          {text: 'Allotment)', value: 33},
          {text: 'Obligation', value: 34},
          {text: 'Disbursement ', value: 35},
        ]; 
      }
      if(this.globalVal2 == 34) {
        return this.get_opcrs_subs[val] = [
          {text: 'Allotment)', value: 33},
          {text: 'Obligation', value: 34},
          {text: 'Disbursement ', value: 35},
        ]; 
      }
    }
  },
  methods: {

  resetPlan() {
    this.ar_plan = {
      plan: '',
      from_date_plan: '',
      to_date_plan: ''
    }
  },
  printAR() {
     this.$htmlToPaper('ar');
  },
  openUpdateAR(item) {

      this.get_opcrs_subs = [
        {text: 'No. of faculty members provided with faculty development grants ', value: 1},
        {text: 'Number of CSP applications received and processed', value: 2},
        {text: 'Number of CSP slots awarded ', value: 3},
        {text: 'Number of  grantees who completed their degrees in CHED priority programs', value: 4},
        {text: 'Number of scholarship grantees completing their courses in AY 2022-2023', value: 5},
        {text: '% of payments received by grantee/HEI within 5 working days of the scheduled payment date', value: 6},
        {text: '"No of HEIs  with CSP monitored XB (221 and above-35%) B (50%) M (65%) S (80%)"', value: 7},
        {text: 'Number of applications for permits (programs/HEIs) received and processed', value: 8},
        {text: 'a. Initial Permit ', value: 9},
        {text: 'b.  Renewal Permits', value: 10},
        {text: 'c.  Government Recognition', value: 11},
        {text: 'd. COPC', value: 12},
        {text: 'Number of LUCs recommended for Certificate of Recognition', value: 13},
        {text: 'a.  Initial Permit ', value: 14},
        {text: 'b.  Renewal Permits', value: 15},
        {text: 'c.  Government Recognition', value: 16},
        {text: 'd. COPC', value: 17},
        {text: 'Number of Programs  evaluated/monitored for PSG compliance  (5% of the total programs for BIG Regions and 7% for MEDIUM regions and 10% for SMALL regions.  B(151 above) M(81-150) S(80 below)', value: 18},
        {text: 'a.  Annual Report (Executive Summary of the quarterly report)', value: 19},
        {text: 'b.  Statistical Bulletin', value: 20},
        {text: 'c.  Regional Website', value: 21},
        {text: 'a. CAV', value: 22},
        {text: 'b.  S.O.', value: 23},
        {text: 'c.  Certification', value: 24},
        {text: '80% of total HEIs MIS data retrieved and validated', value: 25},
        {text: 'a.  Mediation', value: 26},
        {text: 'b.  Investigation ', value: 27},
        {text: 'c.  Conciliation', value: 28},
        {text: 'a. Vouchers Processed', value: 29},
        {text: 'b. Checks Issued', value: 30},
        {text: 'c. LDDAP-ADA issued', value: 31},
        {text: '100% of reports prepared / submitted (to CHED, DBM, COA, CSC, HDMF, GSIS, Ombudsman, CESB, OP, etc.)', value: 32},
        {text: 'Allotment)', value: 33},
        {text: 'Obligation', value: 34},
        {text: 'Disbursement ', value: 35},
        {text: 'Others', value: 36},

      ]
      this.get_opcrs = [
          {text: 'Provision of assistance to HEIs for Local Graduate Studies', value: 1},
          {text: 'No. of activities conducted', value: 2},
          {text: 'Number of HEIs/stakeholders assisted/facilitated from the activities conducted as experts or resource persons:', value: 3},
          {text: 'Provision of assistance, incentives, scholarships and grants through CHED Scholarship Programs', value: 4},
          {text: 'Development of Standards for higher education programs and institutions', value: 5},
          {text: 'Number of HEIs that applied for permits and recognitions', value: 6},
          {text: 'Number of programs subjected to standards (Initial/renewal permits/recognition processed/issued)', value: 7},
          {text: 'Number of curricula evaluated per PSG', value: 8},
          {text: 'Number of applications processed/acted on Tuition and Other Fees increases', value: 9},
          {text: 'Monitoring and evaluation of performance  of HE programs', value: 10},
          {text: 'Enforcement', value: 11},
          {text: 'Number of HEIs subjected to reform (eg merge, close program not in mandate, reorganize BOR, updated curricula)', value: 12},
          {text: '50% of SNPLP borrowers sent demand letters', value: 13},
          {text: 'SUPPORT TO OPERATIONS', value: 14},
          {text: 'Monitoring of HEI Services', value: 15},
          {text: 'No. of HEIs with Tuition Fee increase monitored', value: 16},
          {text: '"No. of HEIs monitored on Student Services XB(35% ) B (50%) M (65%) S(80%)"', value: 17},
          {text: 'No. of IEC materials developed/published (e.g. newsletter, reports, publication)', value: 18},
          {text: 'CHEDRO Frontline Services', value: 19},
          {text: '100% of CAVs, SOs and other certifications processed and acted', value: 20},
          {text: 'Mediation / Investigation / Conciliation', value: 21},
          {text: '100% of the cases acted upon', value: 22},
          {text: 'No. of procurement activities conducted - minimum of 1 per month (12 in a year)', value: 23},
          {text: 'Number of financial transactions: acted, processed and released', value: 24},
          {text: '100% of total communications received processed and acted', value: 25},
          {text: 'GENERAL ADMINISTRATION AND SUPPORT SERVICES', value: 26},
          {text: 'No. of Human Resource Development Activities - minimum of 2 ', value: 27},
          {text: '100% of total plantilla employees attended trainings/seminars/workshops', value: 28},
          {text: 'No. of office planning/assessment activities conducted/attended - minimum of 2', value: 29},
          {text: 'Budget Utilization (in thousand)', value: 30},
          {text: 'Regional GAA', value: 31},
          {text: 'Fund Transfer from DBM Caraga (101)', value: 32},
          {text: 'Fund Transfer from CHEDCO (101)', value: 33},
          {text: 'Fund Transfer (151)', value: 34},
          {text: 'Others', value: 35},

        ]


    this.edit_ar = { ...item };
    console.log("edit ar", this.edit_ar);
    $('#edit-accomplishment').modal('show');
    this.getWork();
    this.getIPCR();
    this.getTransaction();
    this.getAWA();
    console.log(item);
  },
  openUpdateWork(item) {
     this.edit_work = { ...item };
     this.addWork = false;
    console.log("update", item);
  },
  openUpdateIPCR(item){
    this.edit_ipcr = { ...item };
    this.addIPCR = false;
    console.log(item);
  },
  updatePlan(planbyId) {
    $('#edit-plan').modal('show');
    this.editPlan = {...planbyId}
    console.log("plann", this.editPlan);
  },
  updateDate(dateById) {
    $('#edit-date').modal('show');
    this.editDate = {...dateById}
    console.log("as", this.editDate);
  },
  resetFilter() {
    this.getAccomplishmentReports();
    this.total_filters = 0;
  },
  closeFilter() {
    this.filter = false;
  },
    openFilter() {
      this.getIPCR();
      this.filter = true;
      this.get_opcrs_subs = [
        {text: 'No. of faculty members provided with faculty development grants ', value: 1},
        {text: 'Number of CSP applications received and processed', value: 2},
        {text: 'Number of CSP slots awarded ', value: 3},
        {text: 'Number of  grantees who completed their degrees in CHED priority programs', value: 4},
        {text: 'Number of scholarship grantees completing their courses in AY 2022-2023', value: 5},
        {text: '% of payments received by grantee/HEI within 5 working days of the scheduled payment date', value: 6},
        {text: '"No of HEIs  with CSP monitored XB (221 and above-35%) B (50%) M (65%) S (80%)"', value: 7},
        {text: 'Number of applications for permits (programs/HEIs) received and processed', value: 8},
        {text: 'a. Initial Permit ', value: 9},
        {text: 'b.  Renewal Permits', value: 10},
        {text: 'c.  Government Recognition', value: 11},
        {text: 'd. COPC', value: 12},
        {text: 'Number of LUCs recommended for Certificate of Recognition', value: 13},
        {text: 'a.  Initial Permit ', value: 14},
        {text: 'b.  Renewal Permits', value: 15},
        {text: 'c.  Government Recognition', value: 16},
        {text: 'd. COPC', value: 17},
        {text: 'Number of Programs  evaluated/monitored for PSG compliance  (5% of the total programs for BIG Regions and 7% for MEDIUM regions and 10% for SMALL regions.  B(151 above) M(81-150) S(80 below)', value: 18},
        {text: 'a.  Annual Report (Executive Summary of the quarterly report)', value: 19},
        {text: 'b.  Statistical Bulletin', value: 20},
        {text: 'c.  Regional Website', value: 21},
        {text: 'a. CAV', value: 22},
        {text: 'b.  S.O.', value: 23},
        {text: 'c.  Certification', value: 24},
        {text: '80% of total HEIs MIS data retrieved and validated', value: 25},
        {text: 'a.  Mediation', value: 26},
        {text: 'b.  Investigation ', value: 27},
        {text: 'c.  Conciliation', value: 28},
        {text: 'a. Vouchers Processed', value: 29},
        {text: 'b. Checks Issued', value: 30},
        {text: 'c. LDDAP-ADA issued', value: 31},
        {text: '100% of reports prepared / submitted (to CHED, DBM, COA, CSC, HDMF, GSIS, Ombudsman, CESB, OP, etc.)', value: 32},
        {text: 'Allotment)', value: 33},
        {text: 'Obligation', value: 34},
        {text: 'Disbursement ', value: 35},
        {text: 'Others', value: 36},
      ]
      this.get_opcrs = [
          {text: 'Provision of assistance to HEIs for Local Graduate Studies', value: 1},
          {text: 'No. of activities conducted', value: 2},
          {text: 'Number of HEIs/stakeholders assisted/facilitated from the activities conducted as experts or resource persons:', value: 3},
          {text: 'Provision of assistance, incentives, scholarships and grants through CHED Scholarship Programs', value: 4},
          {text: 'Development of Standards for higher education programs and institutions', value: 5},
          {text: 'Number of HEIs that applied for permits and recognitions', value: 6},
          {text: 'Number of programs subjected to standards (Initial/renewal permits/recognition processed/issued)', value: 7},
          {text: 'Number of curricula evaluated per PSG', value: 8},
          {text: 'Number of applications processed/acted on Tuition and Other Fees increases', value: 9},
          {text: 'Monitoring and evaluation of performance  of HE programs', value: 10},
          {text: 'Enforcement', value: 11},
          {text: 'Number of HEIs subjected to reform (eg merge, close program not in mandate, reorganize BOR, updated curricula)', value: 12},
          {text: '50% of SNPLP borrowers sent demand letters', value: 13},
          {text: 'SUPPORT TO OPERATIONS', value: 14},
          {text: 'Monitoring of HEI Services', value: 15},
          {text: 'No. of HEIs with Tuition Fee increase monitored', value: 16},
          {text: '"No. of HEIs monitored on Student Services XB(35% ) B (50%) M (65%) S(80%)"', value: 17},
          {text: 'No. of IEC materials developed/published (e.g. newsletter, reports, publication)', value: 18},
          {text: 'CHEDRO Frontline Services', value: 19},
          {text: '100% of CAVs, SOs and other certifications processed and acted', value: 20},
          {text: 'Mediation / Investigation / Conciliation', value: 21},
          {text: '100% of the cases acted upon', value: 22},
          {text: 'No. of procurement activities conducted - minimum of 1 per month (12 in a year)', value: 23},
          {text: 'Number of financial transactions: acted, processed and released', value: 24},
          {text: '100% of total communications received processed and acted', value: 25},
          {text: 'GENERAL ADMINISTRATION AND SUPPORT SERVICES', value: 26},
          {text: 'No. of Human Resource Development Activities - minimum of 2 ', value: 27},
          {text: '100% of total plantilla employees attended trainings/seminars/workshops', value: 28},
          {text: 'No. of office planning/assessment activities conducted/attended - minimum of 2', value: 29},
          {text: 'Budget Utilization (in thousand)', value: 30},
          {text: 'Regional GAA', value: 31},
          {text: 'Fund Transfer from DBM Caraga (101)', value: 32},
          {text: 'Fund Transfer from CHEDCO (101)', value: 33},
          {text: 'Fund Transfer (151)', value: 34},
          {text: 'Others', value: 35},

      ]
  },
  addARData() {
    console.log("check program val", this.arData[this.globalIndex1]?.ar_opcr);
    this.globalIndex = this.arData.length;
    this.globalIndexx = this.arData.length;
    this.arData.push({
          ar_details: '',
          ar_nature_awa: '1',
          ar_type_transaction: this.arData[this.globalIndex1]?.ar_type_transaction ?? '',
          ar_ipcr_indicator: this.arData[this.globalIndex1]?.ar_ipcr_indicator ?? '',
          ar_origin: 'CHED Caraga',
          ar_date_requested: '',
          ar_due_date: '',
          ar_date_acted: '',
          ar_action_taken: '',
          ar_date_accomplished: '',
          ar_remarks: '',
          ar_program: this.arData[this.globalIndex1]?.ar_program ?? '',
          ar_opcr: this.arData[this.globalIndex1]?.ar_opcr ?? '',
          ar_opcr_sub: this.arData[this.globalIndex2]?.ar_opcr_sub ?? '',
    })
  },
  checkProgram(val, index) {
    this.globalIndex1 = index;
    this.globalVal = val;
      if(val == 1) {
        return this.get_opcrs[index] = [
          {text: 'Provision of assistance to HEIs for Local Graduate Studies', value: 1},
          {text: 'No. of activities conducted', value: 2},
          {text: 'Number of HEIs/stakeholders assisted/facilitated from the activities conducted as experts or resource persons:', value: 3},
          {text: 'Provision of assistance, incentives, scholarships and grants through CHED Scholarship Programs', value: 4},
          {text: 'Others', value: 35},
        ]
      }
      if(val == 2) {
        return this.get_opcrs[index] = [
          {text: 'Development of Standards for higher education programs and institutions', value: 5},
          {text: 'Number of HEIs that applied for permits and recognitions', value: 6},
          {text: 'Number of programs subjected to standards (Initial/renewal permits/recognition processed/issued)', value: 7},
          {text: 'Number of curricula evaluated per PSG', value: 8},
          {text: 'Number of applications processed/acted on Tuition and Other Fees increases', value: 9},
          {text: 'Monitoring and evaluation of performance  of HE programs', value: 10},
          {text: 'Enforcement', value: 11},
          {text: 'Number of HEIs subjected to reform (eg merge, close program not in mandate, reorganize BOR, updated curricula)', value: 12},
          {text: '50% of SNPLP borrowers sent demand letters', value: 13},
          {text: 'SUPPORT TO OPERATIONS', value: 14},
          {text: 'Monitoring of HEI Services', value: 15},
          {text: 'No. of HEIs with Tuition Fee increase monitored', value: 16},
          {text: '"No. of HEIs monitored on Student Services XB(35% ) B (50%) M (65%) S(80%)"', value: 17},
          {text: 'No. of IEC materials developed/published (e.g. newsletter, reports, publication)', value: 18},
          {text: 'CHEDRO Frontline Services', value: 19},
          {text: '100% of CAVs, SOs and other certifications processed and acted', value: 20},
          {text: 'Mediation / Investigation / Conciliation', value: 21},
          {text: '100% of the cases acted upon', value: 22},
          {text: 'No. of procurement activities conducted - minimum of 1 per month (12 in a year)', value: 23},
          {text: 'Number of financial transactions: acted, processed and released', value: 24},
          {text: '100% of total communications received processed and acted', value: 25},
          {text: 'GENERAL ADMINISTRATION AND SUPPORT SERVICES', value: 26},
          {text: 'No. of Human Resource Development Activities - minimum of 2 ', value: 27},
          {text: '100% of total plantilla employees attended trainings/seminars/workshops', value: 28},
          {text: 'No. of office planning/assessment activities conducted/attended - minimum of 2', value: 29},
          {text: 'Budget Utilization (in thousand)', value: 30},
          {text: 'Regional GAA', value: 31},
          {text: 'Fund Transfer from DBM Caraga (101)', value: 32},
          {text: 'Fund Transfer from CHEDCO (101)', value: 33},
          {text: 'Fund Transfer (151)', value: 34},
          {text: 'Others', value: 35},

        ]
      }
    
  },
  async filterOPCR() {
  try {
    const response = await axios.get('api/get-accomplishment-reports-opcr/' + this.ar.ar_opcr)
        .then((response) => {
          this.get_accomplishment_reports = response.data.accomplishment_reports;
          this.total_filters = this.get_accomplishment_reports.length;
          console.log("get_accomplishment_reports", this.get_accomplishment_reports);
    })
  } catch(error) {
        console.log("err", error);
        alert('Something went wrong!');
    }    
  },
  async filterOPCR_SUB() {
    try {
      const response = await axios.get('api/get-accomplishment-reports-opcr_sub/' + this.ar.ar_opcr_sub)
          .then((response) => {
          this.get_accomplishment_reports = response.data.accomplishment_reports;
            this.total_filters = this.get_accomplishment_reports.length;
          console.log("get_accomplishment_reports", this.get_accomplishment_reports);
    })
    } catch(error) {
        console.log("err", error);
        alert('Something went wrong!');
    }    
  },
  async filterIndicator() {
    try {
      const response = await axios.get('api/get-accomplishment-reports-ipcr/' + this.ar.ar_ipcr_indicator)
          .then((response) => {
          this.get_accomplishment_reports = response.data.accomplishment_reports;
          this.total_filters = this.get_accomplishment_reports.length;
          console.log("get_accomplishment_reports", this.get_accomplishment_reports);
    })
    } catch(error) {
        console.log("err", error);
        alert('Something went wrong!');
    }    
  },
  checkOPCR(val, index) {
    this.globalIndex2 = index;
    this.globalVal2 = val;
      if(val == 1) {
        return this.get_opcrs_subs[index] = [
          {text: 'No. of faculty members provided with faculty development grants ', value: 1},
        ]
      }
      if(val == 2 || val == 3 || val == 35) {
        return this.get_opcrs_subs[index] = [
          {text: 'Others', value: 36}, // opcrsubs[0] = [] //opcrsybs[1] = [] // opcsr 2 = []
        ];
      }
      if(val == 4) {
        return this.get_opcrs_subs[index] = [
          {text: 'Number of CSP applications received and processed', value: 2},
          {text: 'Number of CSP slots awarded ', value: 3},
          {text: 'Number of  grantees who completed their degrees in CHED priority programs', value: 4},
          {text: 'Number of scholarship grantees completing their courses in AY 2022-2023', value: 5},
          {text: '% of payments received by grantee/HEI within 5 working days of the scheduled payment date', value: 6},
          {text: '"No of HEIs  with CSP monitored XB (221 and above-35%) B (50%) M (65%) S (80%)"', value: 7},

        ]
      }
      if(val == 5) {
        return this.get_opcrs_subs[index] = [
          {text: 'Number of applications for permits (programs/HEIs) received and processed', value: 8},
        ]
      }

      if(val == 6) {
        return this.get_opcrs_subs[index] = [
          {text: 'a. Initial Permit ', value: 9},
          {text: 'b.  Renewal Permits', value: 10},
          {text: 'c.  Government Recognition', value: 11},
          {text: 'd. COPC', value: 12},
          {text: 'Number of LUCs recommended for Certificate of Recognition', value: 13}
        ]
      }
      if(val == 7) {
        return this.get_opcrs_subs[index] = [
          {text: 'a.  Initial Permit ', value: 14},
          {text: 'b.  Renewal Permits', value: 15},
          {text: 'c.  Government Recognition', value: 16},
          {text: 'd. COPC', value: 17},
        ]
      }
      if(val == 8 || val == 9) {
        return this.get_opcrs_subs[index] = [
          {text: 'Others', value: 36},
        ];
      }
      if(val == 10) {
        return this.get_opcrs_subs[index] = [
          {text: 'Number of Programs  evaluated/monitored for PSG compliance  (5% of the total programs for BIG Regions and 7% for MEDIUM regions and 10% for SMALL regions.  B(151 above) M(81-150) S(80 below)', value: 18},
        ];
      }
      if(val == 11 || val == 12 || val == 13 || val == 14 || val == 15 || val == 16 || val == 17) {
        return this.get_opcrs_subs[index] = [
          {text: 'Others', value: 36},
        ];
      }
      if(val == 18) {
        return this.get_opcrs_subs[index] = [
          {text: 'a.  Annual Report (Executive Summary of the quarterly report)', value: 19},
          {text: 'b.  Statistical Bulletin', value: 20},
          {text: 'c.  Regional Website', value: 21},
        ]
      }
      if(val == 19) {
        return this.get_opcrs_subs[index] = [
          {text: 'Others', value: 36},
        ];
      }
      if(val == 20) {
        return this.get_opcrs_subs[index] = [
          {text: 'a. CAV', value: 22},
          {text: 'b.  S.O.', value: 23},
          {text: 'c.  Certification', value: 24},
          {text: '80% of total HEIs MIS data retrieved and validated', value: 25},
        ];
      }
      if(val == 21) {
        return this.get_opcrs_subs[index] = [
            {text: 'Others', value: 36},
        ];
      }
      if(val == 22) {
        return this.get_opcrs_subs[index] = [
          {text: 'a.  Mediation', value: 26},
          {text: 'b.  Investigation ', value: 27},
          {text: 'c.  Conciliation', value: 28},
        ];
      }
      if(val == 23) {
        return this.get_opcrs_subs[index] = [
          {text: 'Others', value: 36},
        ]; 
      }
      if(val == 24) {
        return this.get_opcrs_subs[index] = [
          {text: 'a. Vouchers Processed', value: 29},
          {text: 'b. Checks Issued', value: 30},
          {text: 'c. LDDAP-ADA issued', value: 31},
        ]; 
      }
      if(val == 25) {
        return this.get_opcrs_subs[index] = [
          {text: '100% of reports prepared / submitted (to CHED, DBM, COA, CSC, HDMF, GSIS, Ombudsman, CESB, OP, etc.)', value: 32},
        ]; 
      }
      if(val == 26 || val == 27 || val == 28 || val == 29 || val == 30) {
        return this.get_opcrs_subs[index] = [
          {text: 'Others', value: 36},
        ]; 
      }
      if(val == 31) {
        return this.get_opcrs_subs[index] = [
          {text: 'Allotment)', value: 33},
          {text: 'Obligation', value: 34},
          {text: 'Disbursement ', value: 35},
        ]; 
      }
      if(val == 32) {
        return this.get_opcrs_subs[index] = [
          {text: 'Allotment)', value: 33},
          {text: 'Obligation', value: 34},
          {text: 'Disbursement ', value: 35},
        ]; 
      }
      if(val == 33) {
        return this.get_opcrs_subs[index] = [
          {text: 'Allotment)', value: 33},
          {text: 'Obligation', value: 34},
          {text: 'Disbursement ', value: 35},
        ]; 
      }
      if(val == 34) {
        return this.get_opcrs_subs[index] = [
          {text: 'Allotment)', value: 33},
          {text: 'Obligation', value: 34},
          {text: 'Disbursement ', value: 35},
        ]; 
      }
  },
  removeARData() {
    this.arData.pop();
    return false;
  },
  deleteActivities(ar_id) {
      let text = "Are you sure you want to delete this task?";
      if (confirm(text) == true) {
          this.loading1 = true;
          let formData = new FormData();
          formData.append('ar_id', ar_id);
        axios
            .post('api/delete-activities', formData)
            .then((response) => {
              toastr.success(response.data.message);  
              $('#edit-activities').modal('hide');
              this.loading1 = false;
              if(this.from && this.to) {
                this.searchLogs(this.from, this.to);
              } else {
                this.getAccomplishmentReports();
              }
            })
            .catch((response) => {
              console.log(response);
              this.loading1 = false;
              alert('Something went wrong!');
            });
      }
    },
  submitUpdatePlan(plan_id) {
      this.loading = true;
      let formData = new FormData();
      formData.append('plan', this.editPlan.plan);
      formData.append('plan_id', plan_id);

      axios
        .post('api/edit-plan', formData)
        .then((response) => {
          toastr.success(response.data.message);  
          $('#edit-plan').modal('hide');
          this.loading = false;
        })
        .catch((response) => {
          console.log(response);
          alert('Something went wrong!');
          this.loading = false;

        });
  },
  previewAR() {
     this.preview = false;
     this.unpreview = true;
     this.printPreview = true;
    },
    unpreviewAR() {
     this.unpreview = false;
     this.preview = true;
     this.printPreview = false;
    },
    storeCSVtoDatabase() {
      this.loading = true;
      axios
        .post('api/upload-areports', {
          data: { reports: this.accomplishment_reportss},
        })
        .then((response) => {
          toastr.success(response.data.message);    
          this.loading = false;
          $('.custom-file-label').html('');
        })
        .catch(() => {
          alert('Something went wrong!');
        });

    },
    uploadCSV(event) {
      let _this = this;

      var csv = event.target.files[0];
      var file = $('#attendance').val();
      var ext = ['csv'];
     
      if(file) {
         var get_ext = file.split('.');
         get_ext = get_ext.reverse();

            if($.inArray ( get_ext[0].toLowerCase(), ext) > -1) {

            const fileName = event.target.files[0].name;
            $('.custom-file-label').html(fileName);

            Papa.parse(csv, {
              complete: function(results) {
                _this.accomplishment_reportss = results.data;
                console.log("accomplishment_reportss logs", _this.accomplishment_reportss);
              },
              skipEmptyLines: true,
              header: true,
            });

          } else {
            toastr.warning('Invalid file! Please upload CSV file.');    
          }
      }
    },
  reference() {

      let d = moment().format('L');
      let t = moment().format('LTS');

      return d + ' ' + t; 
   },
   get_accomplishmentsFunc(date) {

     const result = this.get_accomplishment_reports.filter((ac) => ac.date_of_work === date);

     return result;
   },
   getDivision(id) {
    let division = '';
    this.chedEmployeesUnit.forEach((cosEmployee) => {
          if (cosEmployee.BiometricID == id) {
            division = cosEmployee.division;
          }
        });

        return division;
   },
   getSection(id) {
    let section = '';
    this.chedEmployeesUnit.forEach((cosEmployee) => {
          if (cosEmployee.BiometricID == id) {
            section = cosEmployee.unit_section;
          }
        });

        return section;
   },
   getPreparedBy(id) {

    let prepared = '';
    this.chedEmployees.forEach((chedEmployee) => {
          if (chedEmployee.BiometricID == id) {
            prepared = chedEmployee.prepared;
          }
        });

        return prepared;
    },
    getPreparedPosition(id) {

    let prepared = '';
    this.chedEmployees.forEach((chedEmployee) => {
          if (chedEmployee.BiometricID == id) {
            prepared = chedEmployee.p_position;
          }
        });

        return prepared;
    },
    getReviewed(id) {

    let prepared = '';
    this.chedEmployees.forEach((chedEmployee) => {
          if (chedEmployee.BiometricID == id) {
            prepared = chedEmployee.reviewed;
          }
        });

        return prepared;
    },
    getReviewedPosition(id) {

    let prepared = '';
    this.chedEmployees.forEach((chedEmployee) => {
          if (chedEmployee.BiometricID == id) {
            prepared = chedEmployee.r_position;
          }
        });

        return prepared;
    },
    getNoted(id) {

    let noted = '';
    this.chedEmployees.forEach((chedEmployee) => {
          if (chedEmployee.BiometricID == id) {
            noted = chedEmployee.noted;
          }
        });

        return noted;
    },
    getNotedPosition(id) {

    let noted = '';
    this.chedEmployees.forEach((chedEmployee) => {
          if (chedEmployee.BiometricID == id) {
            noted = chedEmployee.n_position;
          }
        });

        return noted;
    },
   formatDate(date) {
      return moment
        .utc(date, "YYYY-MM-D")
        .locale("en-us")
        .local()
        .format("MMM D YYYY dddd");
        
    },
    openNatureWork() {
      $('#open-nature-work').modal('show');
      this.getWork();
    },
    openIPCRIndicator() {
      $('#open-ipcr-indicator').modal('show');
      this.getIPCR();
    },
    addAccomplishment() {
      $('#add-accomplishment').modal('show');
      this.getWork();
      this.getIPCR();
      this.getTransaction();
      this.getAWA();
    },
    async getAccomplishmentReports() {
      try {
        const response = await axios.get('api/get-accomplishment-reports')
            .then((response) => {
             this.get_accomplishment_reports = response.data.accomplishment_reports;
             console.log("get_accomplishment_reports", this.get_accomplishment_reports);
       })
      } catch(error) {
            console.log("err", error);
            alert('Something went wrong!');
        }
    },
    async getAWA() {
        try {
            const response = await axios.get('api/get-awa')
            .then((response) => {
            this.get_awa = response.data;  
            console.log("awa", this.get_awa);
            })
        } catch(error) {
            console.log("err", error);
            alert('Something went wrong!');
        }
    },
    async getWork() {
        try {
            const response = await axios.get('api/get-work')
            .then((response) => {
            this.get_work = response.data;  
            console.log("work", this.get_work);
            })
        } catch(error) {
            console.log("err", error);
            alert('Something went wrong!');
        }
    },
    async getIPCR() {
        try {
            const response = await axios.get('api/get-ipcr')
            .then((response) => {
            this.get_ipcr = response.data;  
            console.log("ipcr", this.get_ipcr);
            })
        } catch(error) {
            console.log("err", error);
            alert('Something went wrong!');
        }
    },
    async getTransaction() {
        try {
            const response = await axios.get('api/get-transaction')
            .then((response) => {
            this.get_transaction = response.data;  
            console.log("transaction", this.get_transaction);
            })
        } catch(error) {
            console.log("err", error);
            alert('Something went wrong!');
        }
    },
    submitUpdateWork() {
      this.loading = true;
      let formData = new FormData();
      formData.append('work', this.edit_work.work_text);
      formData.append('work_id', this.edit_work.ar_nature_of_work_id);
      axios
        .post('api/update-work', formData)
        .then((response) => {
          toastr.success(response.data.message);  
          this.loading = false;
          this.getWork();
        })
        .catch((response) => {
          console.log(response);
          alert('Something went wrong!');
        });
    },
    submitUpdateIPCR() {
      this.loading = true;
      let formData = new FormData();
      formData.append('ipcr', this.edit_ipcr.ipcr_text);
      formData.append('ipcr_id', this.edit_ipcr.ar_ipcr_indicator_id);
      axios
        .post('api/update-ipcr', formData)
        .then((response) => {
          toastr.success(response.data.message);  
          this.loading = false;
          this.getIPCR();
        })
        .catch((response) => {
          console.log(response);
          alert('Something went wrong!');
        });
    },
    submitWork() {
      this.loading = true;
      let formData = new FormData();
      formData.append('add_work', this.add_work);

      axios
        .post('api/add-work', formData)
        .then((response) => {
          toastr.success(response.data.message);  
          this.add_work = "";
          this.loading = false;
          this.getWork();
        })
        .catch((response) => {
          console.log(response);
          alert('Something went wrong!');
        });
    },
    submitIPCR() {
      this.loading = true;
      let formData = new FormData();
      formData.append('add_ipcr', this.add_ipcr);

      axios
        .post('api/add-ipcr', formData)
        .then((response) => {
          toastr.success(response.data.message);  
          this.loading = false;
          this.getIPCR();
        })
        .catch((response) => {
          console.log(response);
          alert('Something went wrong!');
        });
    },
    submitUpdateAccomplishmentReport() {
      console.log("check update AR", this.edit_ar);
      this.loading = true;
      let formData = new FormData();
      formData.append('ar_accomplishment_id', this.edit_ar.ar_accomplishment_id);
      formData.append('ar_date_work', this.edit_ar.date_of_work);
      formData.append('ar_opcr', this.edit_ar.ar_opcr);
      formData.append('ar_opcr_sub', this.edit_ar.ar_opcr_sub);
      formData.append('ar_program', this.edit_ar.ar_program);
      formData.append('ar_details', this.edit_ar.details);
      formData.append('ar_nature_awa', this.edit_ar.nature_of_awa);
      formData.append('ar_type_transaction', this.edit_ar.type_of_transaction);
      formData.append('ar_ipcr_indicator', this.edit_ar.ipcr_indicator);
      formData.append('ar_origin', this.edit_ar.origin);
      formData.append('ar_date_requested', this.edit_ar.date_requested ?? '');
      formData.append('ar_due_date', this.edit_ar.due_date ?? '');
      formData.append('ar_action_taken', this.edit_ar.action_taken);
      formData.append('ar_date_accomplished', this.edit_ar.accomplished_date ?? '');
      formData.append('ar_remarks', this.edit_ar.remarks);
      axios
        .post('api/edit-accomplishment-report', formData)
        .then((response) => {
          toastr.success(response.data.message);  
          $('#edit-accomplishment').modal('hide');
          this.loading = false;
          if(this.from && this.to) {
            this.searchLogs(this.from, this.to);
          } else {
            this.getAccomplishmentReports();
          }        
          })
        .catch((response) => {
          console.log(response);
          this.loading = false;
          alert('Something went wrong!');
        });
    },
    setData() {
      let formData = new FormData();
      formData.append('arData', JSON.stringify(this.arData));
      formData.append('date_work',this.date_work);
      return formData;
    },
    submitAccomplishmentReport() {
      this.loading = true;
      let data = this.setData();
      axios
        .post('api/add-accomplishment-report', data)     
        .then((response) => {
          toastr.success(response.data.message);  
          $('#add-accomplishment').modal('hide');
          this.loading = false;
          // this.resetAccomplishmentReport();
          this.arData = [];
          if(this.from && this.to) {
            this.searchLogs(this.from, this.to);
          } else {
            this.getAccomplishmentReports();
          }
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;
          alert('Something went wrong!');
        });
    },
    getDate() {
      let m = this.from; // get the month
      let f = this.from; // get date from
      let t = this.to; // get date to

      let month =  String(m[5]) + String(m[6]);
      let year = String(m[0]) + String(m[1]) + String(m[2]) + String(m[3]);
      let dayTo = "";
      let dayFrom = "";
      
      this.months = month;
      this.years = year;

      if(this.to) {
        dayTo = String(t[8]) + String(t[9]);
      }
      if(this.from) {
        dayFrom = String(f[8]) + String(f[9]);
      }

      let d = new Date();

      if(month === '01') {
        return "January" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '02') {
        return "February" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '03') {
        return "March" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '04') {
        return "April" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '05') {
        return "May" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '06') {
        return "June" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '07') {
        return "July" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '08') {
        return "August" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '09') {
        return "September" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '10') {
        return "October" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '11') {
        return "November" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '12') {
        return "December" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else {
        return "";
      } 

    },
    searchLogs(from, to) {
      this.loading = true;
      axios
        .get(
          'api/search-accomplishment-report/' + from + '/' + to
        )
        .then((response) => {
          console.log("accomplishment_reports=>", response);
          this.get_accomplishment_reports = response.data.accomplishment_reports;
          this.get_accomplishment_dates = response.data.accomplishment_dates;
          this.loading = false;
          this.preview = true;
          this.unpreview = false;
          console.log("responsesssssssss", this.get_accomplishment_reports);
          console.log("get acdates", this.get_accomplishment_dates);
        })
        .catch((error) => {
          this.loading = false;
          alert(error);
        });

    },
    resetAccomplishmentReport() {
      this.arData = [
      {
          ar_details: '',
          ar_nature_awa: '',
          ar_type_transaction: '',
          ar_ipcr_indicator: '',
          ar_origin: '',
          ar_date_requested: '',
          ar_due_date: '',
          ar_date_acted: '',
          ar_action_taken: '',
          ar_date_accomplished: '',
          ar_remarks: '',
          ar_program: '',
          ar_opcr: '',
          ar_opcr_sub: ''
        }
      ]
    },
  }
}
</script>

<style>

</style>