var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" "+_vm._s(_vm.caption)+" ")]})],2),_c('CCardBody',[_c('CDataTable',{attrs:{"hover":"","striped":"","border":_vm.border,"small":_vm.small,"fixed":_vm.fixed,"items":_vm.accomplisment_reports,"fields":_vm.fields,"items-per-page":_vm.small ? 20 : 8,"dark":_vm.dark,"pagination":"","table-filter":"","items-per-page-select":"","responsive":""},scopedSlots:_vm._u([{key:"nature_of_AWA",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.awa_text)+" ")])]}},{key:"nature_of_transaction",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.work_text)+" ")])]}},{key:"type_of_transaction",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.transaction_text)+" ")])]}},{key:"IPCR_indicator",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.ipcr_text)+" ")])]}},{key:"date_received",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.date_requested))+" ")])]}},{key:"due_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.due_date))+" ")])]}},{key:"date_acted",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.date_acted))+" ")])]}},{key:"accomplished_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.accomplished_date))+" ")])]}},{key:"date_of_work",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.date_of_work))+" ")])]}},{key:"program",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getProgram(item.ar_program))+" ")])]}},{key:"OPCR_indicator",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getOPCR(item.ar_opcr))+" ")])]}},{key:"OPCR_indicator_SUB",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getOPCRSUB(item.ar_opcr_sub))+" ")])]}},{key:"action_taken_ar",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.details)+" ")])]}},{key:"details_ar",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.action_taken)+" ")])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('button',{staticClass:"btn btn-sm btn-outline-secondary",on:{"click":function($event){$event.preventDefault();return _vm.updateAR(item)}}},[_c('i',{staticClass:"bi bi-pen text-primary"})]),_c('button',{staticClass:"btn btn-sm btn-outline-secondary",on:{"click":function($event){$event.preventDefault();return _vm.deleteAR(item.ar_accomplishment_id)}}},[_c('i',{staticClass:"bi bi-trash text-danger"})])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }