<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid"/> {{caption}}
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        striped
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="accomplisment_reports"
        :fields="fields"
        :items-per-page="small ? 20 : 8"
        :dark="dark"
        pagination
        table-filter
        items-per-page-select
        responsive
      >
        <template #nature_of_AWA="{item}">
          <td>
              {{item.awa_text}}
          </td>     
        </template>
        <template #nature_of_transaction="{item}">
          <td>
              {{item.work_text}}
          </td>     
        </template>
         <template #type_of_transaction="{item}">
          <td>
              {{item.transaction_text}}
          </td>     
        </template>
        <template #IPCR_indicator="{item}">
          <td>
              {{item.ipcr_text}}
          </td>     
        </template>
        <template #date_received="{item}">
          <td>
              {{formatDate(item.date_requested)}}
          </td>     
        </template>
        <template #due_date="{item}">
          <td>
              {{formatDate(item.due_date)}}
          </td>     
        </template>
        <template #date_acted="{item}">
          <td>
              {{formatDate(item.date_acted)}}
          </td>     
        </template>
        <template #accomplished_date="{item}">
          <td>
              {{formatDate(item.accomplished_date)}}
          </td>     
        </template>
        <template #date_of_work="{item}">
          <td>
              {{formatDate(item.date_of_work)}}
          </td>     
        </template>
        <template #program="{item}">
          <td>
              {{getProgram(item.ar_program)}}
          </td>     
        </template>
        <template #OPCR_indicator="{item}">
          <td>
              {{getOPCR(item.ar_opcr)}}
          </td>     
        </template>
        <template #OPCR_indicator_SUB="{item}">
          <td>
              {{getOPCRSUB(item.ar_opcr_sub)}}
          </td>     
        </template>
          <template #action_taken_ar="{item}">
          <td>
              {{ item.details }}
          </td>     
        </template>
          <template #details_ar="{item}">
          <td>
              {{ item.action_taken }}
          </td>     
        </template>
        <template #action="{item}">
          <td>
             <button class="btn btn-sm btn-outline-secondary" @click.prevent="updateAR(item)">
                <i class="bi bi-pen text-primary"></i>
             </button>
              <button class="btn btn-sm btn-outline-secondary" @click.prevent="deleteAR(item.ar_accomplishment_id)">
                <i class="bi bi-trash text-danger"></i>
             </button>
          </td>
        </template>
      </CDataTable>

    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: 'Table',
    data() {
      return {
        get_programs: [
          {text: 'HIGHER EDUCATION DEVELOPMENT PROGRAM', value: 1},
          {text: 'HIGHER EDUCATION REGULATION PROGRAM', value: 2},
        ],
       get_opcrs: [
          {text: 'Provision of assistance to HEIs for Local Graduate Studies', value: 1},
          {text: 'No. of activities conducted', value: 2},
          {text: 'Number of HEIs/stakeholders assisted/facilitated from the activities conducted as experts or resource persons:', value: 3},
          {text: 'Provision of assistance, incentives, scholarships and grants through CHED Scholarship Programs', value: 4},
          {text: 'Development of Standards for higher education programs and institutions', value: 5},
          {text: 'Number of HEIs that applied for permits and recognitions', value: 6},
          {text: 'Number of programs subjected to standards (Initial/renewal permits/recognition processed/issued)', value: 7},
          {text: 'Number of curricula evaluated per PSG', value: 8},
          {text: 'Number of applications processed/acted on Tuition and Other Fees increases', value: 9},
          {text: 'Monitoring and evaluation of performance  of HE programs', value: 10},
          {text: 'Enforcement', value: 11},
          {text: 'Number of HEIs subjected to reform (eg merge, close program not in mandate, reorganize BOR, updated curricula)', value: 12},
          {text: '50% of SNPLP borrowers sent demand letters', value: 13},
          {text: 'SUPPORT TO OPERATIONS', value: 14},
          {text: 'Monitoring of HEI Services', value: 15},
          {text: 'No. of HEIs with Tuition Fee increase monitored', value: 16},
          {text: '"No. of HEIs monitored on Student Services XB(35% ) B (50%) M (65%) S(80%)"', value: 17},
          {text: 'No. of IEC materials developed/published (e.g. newsletter, reports, publication)', value: 18},
          {text: 'CHEDRO Frontline Services', value: 19},
          {text: '100% of CAVs, SOs and other certifications processed and acted', value: 20},
          {text: 'Mediation / Investigation / Conciliation', value: 21},
          {text: '100% of the cases acted upon', value: 22},
          {text: 'No. of procurement activities conducted - minimum of 1 per month (12 in a year)', value: 23},
          {text: 'Number of financial transactions: acted, processed and released', value: 24},
          {text: '100% of total communications received processed and acted', value: 25},
          {text: 'GENERAL ADMINISTRATION AND SUPPORT SERVICES', value: 26},
          {text: 'No. of Human Resource Development Activities - minimum of 2 ', value: 27},
          {text: '100% of total plantilla employees attended trainings/seminars/workshops', value: 28},
          {text: 'No. of office planning/assessment activities conducted/attended - minimum of 2', value: 29},
          {text: 'Budget Utilization (in thousand)', value: 30},
          {text: 'Regional GAA', value: 31},
          {text: 'Fund Transfer from DBM Caraga (101)', value: 32},
          {text: 'Fund Transfer from CHEDCO (101)', value: 33},
          {text: 'Fund Transfer (151)', value: 34},
          {text: 'Others', value: 35},
        ],
        get_opcrs_subs: [
          {text: 'No. of faculty members provided with faculty development grants ', value: 1},
          {text: 'Number of CSP applications received and processed', value: 2},
          {text: 'Number of CSP slots awarded ', value: 3},
          {text: 'Number of  grantees who completed their degrees in CHED priority programs', value: 4},
          {text: 'Number of scholarship grantees completing their courses in AY 2022-2023', value: 5},
          {text: '% of payments received by grantee/HEI within 5 working days of the scheduled payment date', value: 6},
          {text: '"No of HEIs  with CSP monitored XB (221 and above-35%) B (50%) M (65%) S (80%)"', value: 7},
          {text: 'Number of applications for permits (programs/HEIs) received and processed', value: 8},
          {text: 'a. Initial Permit ', value: 9},
          {text: 'b.  Renewal Permits', value: 10},
          {text: 'c.  Government Recognition', value: 11},
          {text: 'd. COPC', value: 12},
          {text: 'Number of LUCs recommended for Certificate of Recognition', value: 13},
          {text: 'a.  Initial Permit ', value: 14},
          {text: 'b.  Renewal Permits', value: 15},
          {text: 'c.  Government Recognition', value: 16},
          {text: 'd. COPC', value: 17},
          {text: 'Number of Programs  evaluated/monitored for PSG compliance  (5% of the total programs for BIG Regions and 7% for MEDIUM regions and 10% for SMALL regions.  B(151 above) M(81-150) S(80 below)', value: 18},
          {text: 'a.  Annual Report (Executive Summary of the quarterly report)', value: 19},
          {text: 'b.  Statistical Bulletin', value: 20},
          {text: 'c.  Regional Website', value: 21},
          {text: 'a. CAV', value: 22},
          {text: 'b.  S.O.', value: 23},
          {text: 'c.  Certification', value: 24},
          {text: '80% of total HEIs MIS data retrieved and validated', value: 25},
          {text: 'a.  Mediation', value: 26},
          {text: 'b.  Investigation ', value: 27},
          {text: 'c.  Conciliation', value: 28},
          {text: 'a. Vouchers Processed', value: 29},
          {text: 'b. Checks Issued', value: 30},
          {text: 'c. LDDAP-ADA issued', value: 31},
          {text: '100% of reports prepared / submitted (to CHED, DBM, COA, CSC, HDMF, GSIS, Ombudsman, CESB, OP, etc.)', value: 32},
          {text: 'Allotment)', value: 33},
          {text: 'Obligation', value: 34},
          {text: 'Disbursement ', value: 35},
          {text: 'Others', value: 36},
        ]
      }
    },
  props: {
    accomplisment_reports: Array,
    fields: {
      type: Array,
      default () {
        return ['details_ar', 'nature_of_AWA', 'program','OPCR_indicator','OPCR_indicator_SUB','type_of_transaction', 'IPCR_indicator', 'origin', 'date_received','due_date', 'action_taken_ar', 'accomplished_date', 'date_of_work', 'action']
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  methods: {
    getProgram(val) {
        let prog = '';
        this.get_programs.forEach(get_program => {
            if (get_program.value == val) {
                prog = get_program.text;
            }
        });
        return prog;
    },
    getOPCR(val) {
        let opcr = '';
        this.get_opcrs.forEach(get_opcr => {
            if (get_opcr.value == val) {
                opcr = get_opcr.text;
            }
        });
        return opcr;
    },
    getOPCRSUB(val) {
        let opcr = '';
        this.get_opcrs_subs.forEach(get_opcr => {
            if (get_opcr.value == val) {
                opcr = get_opcr.text;
            }
        });
        return opcr;
    },
    formatDate(date) {
      return moment
        .utc(date, "YYYY-MM-D")
        .locale("en-us")
        .local()
        .format("MMM D YYYY");
    },
   updateAR(item) {
      this.$emit('updateAR', item);
    },
    deleteAR(item) {
      this.$emit('deleteAR', item);
    }

  }
}
</script>
