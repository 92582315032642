<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid"/> {{caption}}
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        striped
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="ipcr_indicator"
        :fields="fields"
        :items-per-page="small ? 20 : 8"
        :dark="dark"
        pagination
        table-filter
        items-per-page-select
        sorter
      >
        <!-- <template #created_at="{item}">
          <td>
              {{formatDate(item.created_at)}}
          </td>     
        </template>
        <template #updated_at="{item}">
          <td>
              {{formatDate(item.updated_at)}}
          </td>     
        </template>
        <template #amount="{item}">
          <td>
              {{addComma(convertToPeso(item.amount))}}
          </td>     
        </template>
        <template #remaining_balance="{item}">
          <td>
              {{addComma(convertToPeso(item.remaining_balance))}}
          </td>     
        </template>
              <template #file_reference="{item}">
          <td>
              <a :href="'http://api.chedcaraga.ph/storage/scan_documents/Approved_Budget_Request/' + item.budget_amount_id + '.pdf'" target="_blank"> View</a>
          </td>     
        </template>
        -->
        <template #action="{item}">
          <td>
             <button class="btn btn-sm btn-outline-secondary" @click.prevent="updateIPCR(item)">
                <i class="bi bi-pen text-primary"></i>
             </button>
          </td>
        </template>
      </CDataTable>

    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: 'Table',
  props: {
    ipcr_indicator: Array,
    fields: {
      type: Array,
      default () {
        return ['ipcr_text', 'action']
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  methods: {
    updateIPCR(item) {
      this.$emit('updateIPCR', item);
    },


  }
}
</script>
